import { useDesignToken } from "../../context/theme/theme";

export const defaultChartOptions = (
	designTokens: ReturnType<typeof useDesignToken>
): Highcharts.ChartOptions => ({
	alignTicks: true,
	animation: false,
	plotBorderColor: designTokens.ColorGrayLighter,
	plotBorderWidth: 1,
	reflow: true,
	style: {
		fontFamily: "Helvetica, Arial, Serif",
	},
});

export const defaultChartTitleOptions: Highcharts.TitleOptions = {
	text: "",
};

export const defaultChartLegendOptions: Highcharts.LegendOptions = {
	borderColor: "transparent",
	verticalAlign: "top",
	align: "left",
	x: 14,
};

export const defaultChartXAxisOptions = (
	designTokens: ReturnType<typeof useDesignToken>
): Highcharts.XAxisOptions => ({
	lineWidth: 0,
	tickmarkPlacement: "on",
	tickLength: 5,
	tickWidth: 3,
	labels: {
		align: "center",
		y: 30,
	},
	crosshair: {
		dashStyle: "Solid",
		width: 1,
		color: designTokens.ColorGrayLighter,
	},
});

export const defaultChartYAxisOptions = (
	designTokens: ReturnType<typeof useDesignToken>
): Highcharts.YAxisOptions => ({
	lineWidth: 0,
	gridLineWidth: 1,
	minorGridLineWidth: 0,
	gridLineColor: designTokens.ColorGrayLighter,
});

export const defaultChartTooltipOptions = (
	designTokens: ReturnType<typeof useDesignToken>
): Highcharts.TooltipOptions => ({
	shared: true,
	useHTML: true,
	shadow: true,
	backgroundColor: designTokens.ColorWhite,
	borderColor: designTokens.ColorWhite,
	borderRadius: 0,
});

export const defaultChartSeriesOptions = (
	designTokens: ReturnType<typeof useDesignToken>
): Highcharts.PlotOptions => ({
	area: {
		fillOpacity: 0.2,
	},
	pie: {
		dataLabels: {
			style: {
				color: designTokens.ColorGrayDark,
				fontSize: "0.75rem",
				fontWeight: "normal",
				textOutline: "",
			},
		},
	},
});

export const defaultChartCreditsOptions: Highcharts.CreditsOptions = {
	enabled: false,
};

export const defaultChartLangOptions = (
	screenReaderRegionLabel: string,
	screenReaderChartHeading: string,
	defaultChartTitle: string,
	tableSummary: string,
	chartContainerLabel: string,
	legendItem: string,
	chartTypesMapTypeDescription: string,
	chartTypesCombinationChart: string,
	chartTypesDefaultSingle: string,
	chartTypesDefaultMultiple: string,
	chartTypesLineSingle: string,
	chartTypesLineMultiple: string,
	chartTypesBarSingle: string,
	chartTypesBarMultiple: string,
	chartTypesPieSingle: string,
	chartTypesPieMultiple: string,
	xAxisDescriptionSingular: string,
	xAxisDescriptionPlural: string,
	yAxisDescriptionSingular: string,
	yAxisDescriptionPlural: string,
	chartSeriesDefault: string,
	chartSeriesDefaultCombination: string,
	chartSeriesLine: string,
	chartSeriesLineCombination: string,
	chartSeriesColumn: string,
	chartSeriesColumnCombination: string,
	chartSeriesBar: string,
	chartSeriesBarCombination: string,
	chartSeriesPie: string,
	chartSeriesScatterCombination: string,
	chartSeriesMapCombination: string,
	chartSeriesMapbubbleCombination: string,
	xAxisDescription: string,
	yAxisDescription: string,
	datetimeColumnHeader: string,
	categoryColumnHeader: string
): Highcharts.LangOptions => {
	const langOptions: Highcharts.LangOptions = {
		numericSymbols: ["", "M", "G", "T", "P", "E"],
		accessibility: {
			screenReaderSection: {
				afterRegionLabel: screenReaderRegionLabel,
				annotations: {
					heading: screenReaderChartHeading,
				},
			},
			defaultChartTitle: defaultChartTitle,
			table: {
				tableSummary: tableSummary,
			},
			chartContainerLabel: chartContainerLabel,
			legend: {
				legendItem: legendItem,
			},

			chartTypes: {
				mapTypeDescription: chartTypesMapTypeDescription,
				combinationChart: chartTypesCombinationChart,
				defaultSingle: chartTypesDefaultSingle,
				defaultMultiple: chartTypesDefaultMultiple,
				splineSingle: chartTypesLineSingle,
				splineMultiple: chartTypesLineMultiple,
				lineSingle: chartTypesLineSingle,
				lineMultiple: chartTypesLineMultiple,
				columnSingle: chartTypesBarSingle,
				columnMultiple: chartTypesBarMultiple,
				barSingle: chartTypesBarSingle,
				barMultiple: chartTypesBarMultiple,
				pieSingle: chartTypesPieSingle,
				pieMultiple: chartTypesPieMultiple,
			},

			axis: {
				xAxisDescriptionSingular: xAxisDescriptionSingular,
				xAxisDescriptionPlural: xAxisDescriptionPlural,
				yAxisDescriptionSingular: yAxisDescriptionSingular,
				yAxisDescriptionPlural: yAxisDescriptionPlural,
			},

			series: {
				summary: {
					default: chartSeriesDefault,
					defaultCombination: chartSeriesDefaultCombination,
					line: chartSeriesLine,
					lineCombination: chartSeriesLineCombination,
					spline: chartSeriesLine,
					splineCombination: chartSeriesLineCombination,
					column: chartSeriesColumn,
					columnCombination: chartSeriesColumnCombination,
					bar: chartSeriesBar,
					barCombination: chartSeriesBarCombination,
					pie: chartSeriesPie,
					scatterCombination: chartSeriesScatterCombination,
					mapCombination: chartSeriesMapCombination,
					mapbubbleCombination: chartSeriesMapbubbleCombination,
				},

				xAxisDescription: xAxisDescription,
				yAxisDescription: yAxisDescription,
			},
		},
		exportData: {
			categoryDatetimeHeader: datetimeColumnHeader,
			categoryHeader: categoryColumnHeader,
		},
	};

	return langOptions;
};

export const defaultChartExportingOptions: Highcharts.ExportingOptions = {
	enabled: false,
};

export const defaultChartAccessibilityOptions = (
	beforeChartFormatTemplate: string
): Highcharts.AccessibilityOptions => {
	const accessibilityOptions: Highcharts.AccessibilityOptions = {
		landmarkVerbosity: "disabled",
		point: {
			dateFormat: "%B %e, %Y",
		},
		screenReaderSection: {
			beforeChartFormat: beforeChartFormatTemplate,
			onViewDataTableClick: undefined,
		},
		keyboardNavigation: {
			order: ["legend", "series"],
		},
	};

	return accessibilityOptions;
};
