import React from "react";
import { Button, cn, Icon, IconSettings, InlineText, Tooltip } from "@siteimprove/fancylib";
import { Popover } from "../../overlay/popover/popover";
import { ToggleSwitch } from "../../actions-and-controls/toggle-switch/toggle-switch";
import * as scss from "./chart-context-menu.scss";

// Due to an issue in the typescript-to-proptypes tool used by
// build-utils/knobsParser.js, tuple types are not supported. So, we need to use
// `any` to describe state-like props, such as `enhancedContrastState` and
// `tableViewState`. The correct type for these props would be
// `[boolean, (value: boolean) => void]` or `UseState<boolean>`.
/* eslint-disable @typescript-eslint/no-explicit-any */
export type ChartContextMenuProps = {
	contextMenuLabel: string;
	enhancedContrastState: any;
	enhancedContrastLabel: string;
	enhancedContrastTooltip: string;
	tableViewState: any;
	tableViewLabel: string;
	tableViewTooltip: string;
	hideEnhancedContrastOption?: boolean;
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export function ChartContextMenu(props: ChartContextMenuProps): JSX.Element {
	const {
		contextMenuLabel,
		enhancedContrastState,
		enhancedContrastLabel,
		enhancedContrastTooltip,
		tableViewState,
		tableViewLabel,
		tableViewTooltip,
		hideEnhancedContrastOption,
	} = props;
	return (
		<Popover
			aria-label={contextMenuLabel}
			hideChevron
			buttonContent={
				<Icon>
					<IconSettings />
				</Icon>
			}
			popoverContent={(id, firstFocusableRef, { setIsOpen }) => (
				<div id={id} className={cn(scss.contextMenuList)}>
					{!hideEnhancedContrastOption && (
						<div className={cn(scss.item, scss.toggleItem)} ref={firstFocusableRef}>
							<InlineText tone="neutralDark" className={scss.toggleItemLabel}>
								<Tooltip variant={{ type: "text" }} content={enhancedContrastTooltip}>
									{enhancedContrastLabel}
								</Tooltip>
							</InlineText>
							<ToggleSwitch
								aria-label={enhancedContrastLabel}
								className={cn(scss.toggleItem)}
								value={enhancedContrastState[0]}
								onChange={enhancedContrastState[1]}
							/>
						</div>
					)}
					<div className={cn(scss.item)}>
						<Button
							className={cn(scss.button)}
							variant="borderless"
							onClick={() => {
								tableViewState[1](!tableViewState[0]);
								setIsOpen(false);
							}}
						>
							{tableViewLabel}
						</Button>
						<Tooltip
							variant={{
								type: "icon-only",
							}}
							content={tableViewTooltip}
						>
							<Icon size={Tooltip.iconSize}>{Tooltip.icon}</Icon>
						</Tooltip>
					</div>
				</div>
			)}
		/>
	);
}
