import { AnnotationIconProps } from "./annotation-icon-tooltip";
import { AnnotationItem } from "./annotations";

export function updateIconsProps(
	annotations: AnnotationItem[] | undefined,
	iconsProps: AnnotationIconProps[],
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
	i18n: any
): AnnotationIconProps[] {
	if (typeof document === "undefined" || !annotations) return [];

	const updatedIconsProps = [...iconsProps];

	const highchartLabels = document.querySelectorAll(
		"div.highcharts-label.highcharts-annotation-label"
	);

	for (let i = 0; i < highchartLabels.length; i++) {
		const element = highchartLabels[i] as HTMLElement;
		const elementClasses = [...element.classList];

		const isSystemNotification = elementClasses.includes("annotation-system-notification");
		const hasMultipleAnnotations = elementClasses.includes("multiple-annotations");
		const ids = elementClasses
			.filter((c) => c.startsWith("annotation-"))
			.map((c) => c.split("annotation-")[1]);

		const iconAnnotations = annotations.filter((annotation) =>
			ids.includes(annotation.id.toString())
		);

		if (iconAnnotations.length > 0) {
			const contentHeader = iconAnnotations[0].date.toISOString().split("T")[0];
			const contentText = hasMultipleAnnotations
				? `${i18n.annotations} ${iconAnnotations.length}`
				: iconAnnotations[0].comment;

			const existingIndex = iconsProps.findIndex(
				(iconProp) =>
					iconProp.coordinates.left === element.offsetLeft &&
					iconProp.coordinates.top === element.offsetTop
			);

			if (existingIndex === -1) {
				updatedIconsProps.push({
					coordinates: {
						top: element.offsetTop,
						left: element.offsetLeft,
					},
					content: {
						header: contentHeader,
						text: contentText,
					},
					typeOfAnnotation: hasMultipleAnnotations
						? "multiple"
						: isSystemNotification
						? "siteImprove"
						: "single",
					ids,
				});
			}
		}
	}

	return updatedIconsProps;
}

type AnnotationLabel = {
	crop: boolean;
	className: string;
	point: {
		xAxis: number;
		yAxis: number;
		y: number;
		x: number;
	};
};

export function createAnnotationsLabels(
	annotations: AnnotationItem[],
	chartPeriod: Date[],
	yAxisMin: number
): AnnotationLabel[] {
	const datesInChart: {
		ids: (string | number)[];
		date: number;
		times: number;
		isSystemNotification: boolean | undefined;
	}[] = [];

	annotations.forEach((annotation) => {
		if (annotation.date >= chartPeriod[0] && annotation.date <= chartPeriod[1]) {
			const utcDate = Date.UTC(
				annotation.date.getUTCFullYear(),
				annotation.date.getUTCMonth(),
				annotation.date.getUTCDate()
			);
			const dateIndex = datesInChart.findIndex((value) => value.date === utcDate);

			if (dateIndex === -1) {
				datesInChart.push({
					ids: [annotation.id],
					date: utcDate,
					times: 1,
					isSystemNotification: annotation.isSystemNotification,
				});
			} else {
				datesInChart[dateIndex].ids.push(annotation.id);
				datesInChart[dateIndex].times++;
			}
		}
	});

	const annotationsLabels = datesInChart.map((value) => {
		return {
			crop: false,
			className:
				value.times > 1
					? `multiple-annotations ${value.ids.map((id) => "annotation-" + id).join(" ")}`
					: value.isSystemNotification
					? `annotation-${value.ids[0]} annotation-system-notification`
					: `annotation-${value.ids[0]}`,
			point: {
				xAxis: 0,
				yAxis: 0,
				y: yAxisMin,
				x: value.date,
			},
		};
	});

	return annotationsLabels;
}
