import React, { useMemo, useState } from "react";
import Highcharts from "highcharts";
import { Modal } from "../../overlay/modal/modal";
import { ColumnConfig } from "../../tables-and-lists/base-table/base-table";
import { Table, invertDirection } from "../../tables-and-lists/table/table";
import { colDateTime } from "../../tables-and-lists/configs/table-config";
import { SortField } from "../../tables-and-lists/column-header/column-header";
import * as scss from "./data-table-modal.scss";

type TDto = Record<string | number, string | number | Date>;

export type DataTableModalProps = {
	dataRows: ReturnType<Highcharts.Chart["getDataRows"]>;
	axisType?: string;
	chartTitle: string;
	locale: string;
	onClose: () => void;
	show: boolean;
};

export function DataTableModal(props: DataTableModalProps): JSX.Element {
	const { axisType, dataRows, chartTitle, locale, onClose, show } = props;

	const isEmpty = dataRows.length === 0 || (dataRows.length == 1 && dataRows[0].length === 0);
	const axisIsDatetime = axisType == "datetime";
	const columnHeaders: string[] = dataRows[0] as string[];

	// Column definitions for the "columns" prop of the Table component
	const columnDefinitions: ColumnConfig<TDto>[] = columnHeaders.map((column, columnIndex) => {
		let columnData: ColumnConfig<TDto>;
		if (axisIsDatetime && columnIndex == 0) {
			columnData = colDateTime(column, locale, column, {}, undefined, true) as ColumnConfig<TDto>;
		} else {
			columnData = {
				header: {
					property: column,
					content: column,
				},
				render: (dto) => dto[column],
			};
		}

		if (columnIndex == 0) {
			columnData.options = {
				isKeyColumn: columnIndex == 0,
			};
		}
		return columnData;
	});

	const [sort, setSort] = useState<SortField<TDto>>({
		property: "title",
		direction: "asc",
	});

	// Transform data from Highcharts
	const items = useMemo<TDto[]>(() => {
		const itemsData: TDto[] = [];

		dataRows.forEach((dataRow: (string | number)[], rowIndex: number) => {
			if (rowIndex == 0) return;

			// Filtering the first item in the array, since it's always
			// (at least until now) filled with the point name in X axis.
			// Then, we check if every other column has a falsy value, except 0. If it's true, we skip the row.
			if (dataRow.filter((_, index) => index != 0).every((dr) => !dr && dr !== 0)) return;

			const parsedRow = dataRow.reduce<TDto>((newObject, column, columnIndex) => {
				const headerKey = columnHeaders[columnIndex];

				if (axisIsDatetime && columnIndex == 0) {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					newObject[headerKey] = new Date((dataRows[rowIndex] as any).x);
				} else {
					newObject[headerKey] = column;
				}
				return newObject;
			}, {} as TDto);
			itemsData.push(parsedRow);
		});
		return itemsData.sort(itemsSorter(sort));
	}, [dataRows, sort]);

	return (
		<Modal shown={show} headerTitle={chartTitle} onClose={onClose}>
			<Modal.Content padding="none">
				<Table
					withoutKeyColumn={isEmpty}
					className={scss.noBorder}
					columns={columnDefinitions}
					items={items}
					sort={sort}
					setSort={(property, direction) => {
						setSort({
							property: property,
							direction: property === sort.property ? invertDirection(sort.direction) : direction,
						});
					}}
					caption="Basic usage table"
					loading={false}
				/>
			</Modal.Content>
		</Modal>
	);
}

function itemsSorter<TDto>(sort: SortField<TDto>) {
	return (a: TDto, b: TDto) => {
		const first = sort.direction === "asc" ? a : b;
		const second = sort.direction === "asc" ? b : a;
		const v1 = first[sort.property];
		const v2 = second[sort.property];
		if (typeof v1 === "string" && typeof v2 === "string") {
			return v1.localeCompare(v2, undefined, {
				numeric: true,
				sensitivity: "base",
			});
		}

		return v1 > v2 ? 1 : v2 > v1 ? -1 : 0;
	};
}
