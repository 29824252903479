import React, { useState } from "react";
import { Button, Content, InlineText, Paragraph } from "@siteimprove/fancylib";
import { Example, Header, Code } from "../../../../../src/docs";
import { useDesignToken } from "../../context/theme/theme";
import { Chart, ChartProps } from "./chart";

export default (): JSX.Element => (
	<>
		<Header.H3>Usage with reference lines</Header.H3>
		<Example fn={UsageWithReferenceLines} />
		<Header.H3>Usage with no legend</Header.H3>
		<Example fn={UsageWithNoLegend} />
		<Header.H3>Usage with series hidden on load</Header.H3>
		<Example fn={UsageWithSeriesHiddenOnLoad} />
		<Header.H3>Usage with series not visible in the legends</Header.H3>
		<Example fn={UsageWithSeriesNotVisibleInLegend} />
		<Header.H3>Usage with missing data</Header.H3>
		<Paragraph>
			If all series contain empty data (<Code>undefined</Code>, <Code>null</Code> or{" "}
			<Code>NaN</Code>) in a determined position, the row will not be displayed on the table
			visualization.
		</Paragraph>
		<Example fn={UsageWithMissingData} />
		<Header.H3>Usage with tooltip formatters</Header.H3>
		<Paragraph>
			The header of the chart tooltip can be formatted using the optional property{" "}
			<Code>tooltipHeaderFormatter</Code> with the values <Code>default</Code>, <Code>named</Code>,
			and <Code>keyed</Code>.
		</Paragraph>
		<Paragraph>
			The individual points of the chart tooltip can be formatted using the optional property{" "}
			<Code>tooltipPointFormatter</Code> with the values <Code>default</Code>, <Code>named</Code>,
			and <Code>customValue</Code>.
		</Paragraph>
		<Example fn={UsageWithTooltipFormatters} />
		<Header.H3>Visual</Header.H3>
		<Header.H4>Height</Header.H4>
		<Paragraph>
			Use the <Code>height</Code> property to adjust the chart size. The default height is{" "}
			<Code>medium</Code> (<Code>310px</Code>) and should be used in most cases. Use{" "}
			<Code>small</Code> height when space is limited. Use <Code>large</Code> or a custom value when
			the previous sizes are not suitable.
		</Paragraph>
		<Example fn={UsageWithSize} />
		<Header.H3>Usage with context menu changes</Header.H3>
		<Paragraph>
			Use the <Code>hideContextMenu</Code> property to remove the Context Menu gear icon. The
			default setting is to show the Context Menu. In addition, use the{" "}
			<Code>hideEnhancedContrastOption</Code> property to remove the Enhanced contrast option from
			the Conext menu when that is visible. The default setting is to show the Enhanced Contrast
			option.
		</Paragraph>
		<Paragraph>
			In the example below, the <Code>hideContextMenu</Code> is disabled and the{" "}
			<Code>hideEnhancedContrastOption</Code> is enabled.
		</Paragraph>
		<Example fn={UsageWithCustomContextMenu} />
		<Header.H3>Usage with persistent legend state</Header.H3>
		<Paragraph>
			Use the <Code>onToggleSeries</Code> property to persist the legend state. The default setting
			is to not persist the legend state, which means that the legend state will be reset when the
			page is refreshed. The visibility state of each series is controlled by the{" "}
			<Code>visible</Code> property of the series object.
		</Paragraph>
		<Example fn={UsageWithLegendStatePersistence} />
		<Header.H3>Data updates</Header.H3>
		<Paragraph>
			The <Code>Chart</Code> component automatically updates the chart when the <Code>options</Code>{" "}
			or <Code>height</Code> properties change. This is useful when the chart data is updated
			dynamically or the layout changes and the chart needs to be redrawn.
		</Paragraph>
		<Example fn={UsageWithDataUpdates} />
		<Header.H3>Usage with no data</Header.H3>
		<Paragraph>
			In some real-world scenarios, the chart may not have any data to display. In this case, the
			chart frame and the table view will be empty.
		</Paragraph>
		<Example fn={UsageWithNoData} />
	</>
);

/** @ignore [visual] [a11y:R54] */
const UsageWithReferenceLines = () => {
	const { ColorRed, ColorTextStaticBody } = useDesignToken();
	return (
		<Chart
			options={{
				yAxis: {
					plotLines: [
						{
							value: 70,
							color: ColorRed,
							width: 3,
							dashStyle: "ShortDot",
							label: {
								text: "Limit (70)",
								align: "right",
								x: -5,
								style: {
									color: ColorTextStaticBody,
								},
							},
						},
					],
				},
				series: [
					{
						type: "line",
						name: "Series 1",
						data: [25, 23, 48, 52, 52, 57, 56, 61, 66].map((val, i) => {
							return {
								x: i,
								y: val,
								name: i.toString(),
							};
						}),
					},
					{
						type: "line",
						name: "Series 2",
						data: [15, 13, 38, 42, 42, 47, 46, 51, 56].map((val, i) => {
							return {
								x: i,
								y: val,
								name: i.toString(),
							};
						}),
					},
				],
			}}
			{...translations}
		/>
	);
};

/** @ignore [visual] [a11y:R54] */
const UsageWithNoLegend = () => (
	<Chart
		options={{
			plotOptions: {
				series: {
					showInLegend: false,
				},
			},
			series: [
				{
					type: "column",
					name: "Series 1",
					data: [25, 23, 48, 52, 52, 57, 56, 61, 66].map((val, i) => {
						return {
							x: i,
							y: val,
							name: i.toString(),
							customValue: "customValue1",
						};
					}),
				},
				{
					type: "line",
					name: "Series 2",
					data: [18, 26, 36, 44, 49, 59, 65, 71, 82].map((val, i) => {
						return {
							x: i,
							y: val,
							name: i.toString(),
							customValue: "customValue2",
						};
					}),
				},
			],
		}}
		{...translations}
	/>
);

/** @ignore [visual] [a11y:R54] */
const UsageWithLegendStatePersistence = () => {
	const [series, setSeries] = useState<Highcharts.SeriesOptionsType[]>([
		{
			type: "column",
			name: "Series 1",
			visible: true,
			data: [25, 23, 48, 52, 52, 57, 56, 61, 66].map((val, i) => {
				return {
					x: i,
					y: val,
					name: i.toString(),
					customValue: "customValue1",
				};
			}),
		},
		{
			type: "line",
			name: "Series 2",
			visible: false,
			data: [18, 26, 36, 44, 49, 59, 65, 71, 82].map((val, i) => {
				return {
					x: i,
					y: val,
					name: i.toString(),
					customValue: "customValue2",
				};
			}),
		},
	]);
	return (
		<Chart
			onToggleSeries={(seriesIndex, visible) => {
				const newSeries = [...series];
				newSeries[seriesIndex].visible = visible;
				setSeries(newSeries);
				console.log(
					"Save series visibility to user preferences (e.g.: localStorage)",
					seriesIndex,
					visible
				);
			}}
			options={{ series }}
			{...translations}
		/>
	);
};

/** @ignore [visual] [a11y:R54] */
const UsageWithSeriesHiddenOnLoad = () => (
	<Chart
		options={{
			series: [
				{
					type: "column",
					name: "Series 1",
					data: [25, 23, 48, 52, 52, 57, 56, 61, 66].map((val, i) => {
						return {
							x: i,
							y: val,
							name: i.toString(),
						};
					}),
				},
				{
					type: "line",
					name: "Series 2",
					data: [18, 26, 36, 44, 49, 59, 65, 71, 82].map((val, i) => {
						return {
							x: i,
							y: val,
							name: i.toString(),
						};
					}),
					selected: false,
				},
			],
		}}
		{...translations}
	/>
);

/** @ignore [visual] [a11y:R54] */
const UsageWithSeriesNotVisibleInLegend = () => (
	<Chart
		options={{
			series: [
				{
					type: "column",
					name: "Series 1",
					data: [25, 23, 48, 52, 52, 57, 56, 61, 66].map((val, i) => {
						return {
							x: i,
							y: val,
							name: i.toString(),
						};
					}),
				},
				{
					showInLegend: false,
					type: "line",
					name: "Series 2",
					data: [18, 26, 36, 44, 49, 59, 65, 71, 82].map((val, i) => {
						return {
							x: i,
							y: val,
							name: i.toString(),
						};
					}),
				},
			],
		}}
		{...translations}
	/>
);

/** @ignore [visual] [a11y:R54] */
const UsageWithTooltipFormatters = () => (
	<Chart
		options={{
			series: [
				{
					type: "column",
					name: "Series 1",
					data: [25, 23, 48, 52, 52, 57, 56, 61, 66].map((val, i) => {
						return {
							x: i,
							y: val,
							name: "Name for x: " + i.toString(),
							customValue: "Custom value for x: " + i.toString() + " in series 1",
						};
					}),
				},
				{
					type: "line",
					name: "Series 2",
					data: [18, 26, 36, 44, 49, 59, 65, 71, 82].map((val, i) => {
						return {
							x: i,
							y: val,
							name: "Name for x: " + i.toString(),
							customValue: "Custom value for x: " + i.toString() + " in series 2",
						};
					}),
				},
			],
		}}
		{...translations}
		tooltipHeaderFormatter="named"
		tooltipPointFormatter="customValue"
	/>
);

/** @ignore [visual] [a11y:R54] */
const UsageWithMissingData = () => (
	<Chart
		options={{
			series: [
				{
					type: "column",
					name: "Series 1",
					data: [25, 23, 48, 52, undefined, 52, 57, 56, 61].map((val, i) => {
						return {
							x: i,
							y: val,
						};
					}),
				},
				{
					type: "line",
					name: "Series 2",
					data: [18, 26, 36, 44, undefined, 49, 59, 65, 71].map((val, i) => {
						return {
							x: i,
							y: val,
						};
					}),
				},
			],
		}}
		{...translations}
	/>
);

/** @ignore [visual] [a11y:R54] */
const UsageWithSize = () => {
	function BaseChart(props: { height: ChartProps["height"] }) {
		return (
			<>
				<InlineText emphasis="medium">{props.height}</InlineText>
				<br />
				<Chart
					height={props.height}
					hideContextMenu
					options={{
						series: [
							{
								type: "line",
								name: "Series 1",
								data: [25, 23, 48, 52, 52, 57, 56, 61, 66].map((val, i) => {
									return {
										x: i,
										y: val,
										name: val.toString(),
									};
								}),
							},
						],
					}}
					{...translations}
				/>
			</>
		);
	}

	return (
		<>
			<BaseChart height="small" />
			<BaseChart height="medium" />
			<BaseChart height="large" />
		</>
	);
};

/** @ignore [visual] [a11y:R54] */
const UsageWithCustomContextMenu = () => (
	<Chart
		hideContextMenu={false}
		hideEnhancedContrastOption
		options={{
			series: [
				{
					type: "column",
					name: "Series 1",
					data: [25, 23, 48, 52, 52, 57, 56, 61, 66].map((val, i) => {
						return {
							x: i,
							y: val,
							name: i.toString(),
							customValue: "customValue1",
						};
					}),
				},
				{
					type: "line",
					name: "Series 2",
					data: [18, 26, 36, 44, 49, 59, 65, 71, 82].map((val, i) => {
						return {
							x: i,
							y: val,
							name: i.toString(),
							customValue: "customValue2",
						};
					}),
				},
			],
		}}
		{...translations}
	/>
);

/** @ignore [visual] [a11y:R54] */
const UsageWithDataUpdates = () => {
	const numberOfSeries = 2;
	const seriesLength = 10;
	const buildData = () => generateRandomData(numberOfSeries, seriesLength);
	const [series, setSeries] = useState<number[][]>(buildData());
	return (
		<Content>
			<Button onClick={() => setSeries(buildData())}>Generate series</Button>
			<Chart
				options={{
					series: [
						{
							type: "column",
							name: "Series 1",
							data: series[0].map((val, i) => {
								return {
									x: i,
									y: val,
									name: i.toString(),
									customValue: "customValue1",
								};
							}),
						},
						{
							type: "line",
							name: "Series 2",
							data: series[1].map((val, i) => {
								return {
									x: i,
									y: val,
									name: i.toString(),
									customValue: "customValue2",
								};
							}),
						},
					],
				}}
				{...translations}
			/>
		</Content>
	);
};

/** @ignore [visual] [a11y:R54] */
const UsageWithNoData = () => (
	<Chart
		options={{
			series: [],
		}}
		{...translations}
	/>
);

function generateRandomData(numberOfSeries: number, seriesLength: number): number[][] {
	const series = [];
	for (let i = 0; i < numberOfSeries; i++) {
		const data = [];
		for (let j = 0; j < seriesLength; j++) {
			data.push(Math.floor(Math.random() * 100));
		}
		series.push(data);
	}
	return series;
}

const translations = {
	screenReaderRegionLabel: "Chart",
	screenReaderChartHeading: "Chart graphic",
	defaultChartTitle: "Chart title",
	tableSummary: "Table representation of chart.",
	chartContainerLabel: "{title}. Use up and down arrows to navigate with most screen readers.",
	legendItem: "Enable series: {itemName}",
	chartTypesMapTypeDescription: "Map of {mapTitle} with {numSeries} data series.",
	chartTypesCombinationChart: "Combination chart with {numSeries} data series.",
	chartTypesDefaultSingle: "Chart with {numPoints} data {#plural(numPoints, points, point)}.",
	chartTypesDefaultMultiple: "Chart with {numSeries} data series.",
	chartTypesSplineSingle: "Line chart with {numPoints} data {#plural(numPoints, points, point)}.",
	chartTypesSplineMultiple: "Line chart with {numSeries} lines.",
	chartTypesLineSingle: "Line chart with {numPoints} data {#plural(numPoints, points, point)}.",
	chartTypesLineMultiple: "Line chart with {numSeries} lines.",
	chartTypesColumnSingle: "Bar chart with {numPoints} {#plural(numPoints, bars, bar)}.",
	chartTypesColumnMultiple: "Bar chart with {numSeries} data series.",
	chartTypesBarSingle: "Bar chart with {numPoints} {#plural(numPoints, bars, bar)}.",
	chartTypesBarMultiple: "Bar chart with {numSeries} data series.",
	chartTypesPieSingle: "Pie chart with {numPoints} {#plural(numPoints, slices, slice)}.",
	chartTypesPieMultiple: "Pie chart with {numSeries} pies.",
	xAxisDescriptionSingular: "X axis that shows {names[0]}.",
	xAxisDescriptionPlural:
		"The chart has {numAxes} X axes that show {#each(names, -1) }and {names[-1]}",
	categoryColumnHeader: "Category",
	datetimeColumnHeader: "Date and/or time",
	yAxisDescriptionSingular: "Y axis that shows {names[0]}.",
	yAxisDescriptionPlural:
		"The chart has {numAxes} Y axes that show {#each(names, -1) }and {names[-1]}",
	chartSeriesDefault:
		"{name}, series {ix} of {numSeries} with {numPoints} data {#plural(numPoints, points, point)}.",
	chartSeriesDefaultCombination:
		"{name}, series {ix} of {numSeries} with {numPoints} data {#plural(numPoints, points, point)}.",
	chartSeriesLine:
		"{name}, line {ix} of {numSeries} with {numPoints} data {#plural(numPoints, points, point)}.",
	chartSeriesLineCombination:
		"{name}, series {ix} of {numSeries}. Line with {numPoints} data {#plural(numPoints, points, point)}.",
	chartSeriesSpline:
		"{name}, line {ix} of {numSeries} with {numPoints} data {#plural(numPoints, points, point)}.",
	chartSeriesSplineCombination:
		"{name}, series {ix} of {numSeries}. Line with {numPoints} data {#plural(numPoints, points, point)}.",
	chartSeriesColumn:
		"{name}, bar series {ix} of {numSeries} with {numPoints} {#plural(numPoints, bars, bar)}.",
	chartSeriesColumnCombination:
		"{name}, series {ix} of {numSeries}. Bar series with {numPoints} {#plural(numPoints, bars, bar)}.",
	chartSeriesBar:
		"{name}, bar series {ix} of {numSeries} with {numPoints} {#plural(numPoints, bars, bar)}.",
	chartSeriesBarCombination:
		"{name}, series {ix} of {numSeries}. Bar series with {numPoints} {#plural(numPoints, bars, bar)}.",
	chartSeriesPie:
		"{name}, pie {ix} of {numSeries} with {numPoints} {#plural(numPoints, slices, slice)}.",
	chartSeriesScatterCombination:
		"{name}, series {ix} of {numSeries}, scatter plot with {numPoints} {#plural(numPoints, points, point)}.",
	chartSeriesMapCombination:
		"{name}, series {ix} of {numSeries}. Map with {numPoints} {#plural(numPoints, areas, area)}.",
	chartSeriesMapbubbleCombination:
		"{name}, series {ix} of {numSeries}. Bubble series with {numPoints} {#plural(numPoints, bubbles, bubble)}.",
	xAxisDescription: "X axis, {name}",
	yAxisDescription: "Y axis, {name}",
	contextMenuForLabel: "Context menu for",
	contextMenuEnhancedContrastLabel: "Enhanced contrast",
	contextMenuEnhancedContrastTooltip:
		"Makes series more clear by introducing patterns and other visual aids.",
	contextMenuTableViewLabel: "Open table data view",
	contextMenuTableViewTooltip: "The table data view allows you to view the data in a table format.",
};
