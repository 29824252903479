import React, { useState } from "react";
import {
	Button,
	cn,
	H6,
	Icon,
	IconChatBubble,
	IconQuestionAnswer,
	IconSiteImprove,
	InlineText,
} from "@siteimprove/fancylib";
import { useDesignToken } from "../../../context/theme/theme";
import * as scss from "./annotation-icon-tooltip.scss";

// Annotation Icon

export type AnnotationType = "single" | "multiple" | "siteImprove";

export type AnnotationIconProps = {
	coordinates: {
		top: number;
		left: number;
	};
	content: {
		header: string;
		text: string;
	};
	typeOfAnnotation: AnnotationType;
	ids: (string | number)[];
};

export type AnnotationIconTooltipProps = {
	setAnnotationsHighlightedIds: (highlightedIds: (string | number)[]) => void;
	setShowAnnotations: (showAnnotations: boolean) => void;
} & AnnotationIconProps;

export function AnnotationIconTooltip(props: AnnotationIconTooltipProps): JSX.Element {
	const {
		coordinates,
		content,
		typeOfAnnotation,
		ids,
		setAnnotationsHighlightedIds,
		setShowAnnotations,
	} = props;

	function getAnnotationIcon(annotationType: AnnotationType): JSX.Element {
		switch (annotationType) {
			case "single":
				return <IconChatBubble />;
			case "multiple":
				return <IconQuestionAnswer />;
			default:
				return <IconSiteImprove />;
		}
	}

	const icon = getAnnotationIcon(typeOfAnnotation);

	const [showTooltip, setShowTooltip] = useState(false);
	const { ColorBlue } = useDesignToken();

	const iconLeftOffset = 5;
	const popoverTopOffset = 30;

	return (
		<div>
			<Button
				size="small"
				aria-label={`annotation-icon-${typeOfAnnotation}`}
				style={{
					position: "absolute",
					left: coordinates.left + iconLeftOffset,
					top: coordinates.top,
				}}
				onClick={() => {
					setAnnotationsHighlightedIds(ids);
					setShowAnnotations(true);
				}}
				onFocus={() => {
					setShowTooltip(true);
					setAnnotationsHighlightedIds(ids);
					setShowAnnotations(true);
				}}
				onBlur={() => setShowTooltip(false)}
				onMouseEnter={() => setShowTooltip(true)}
				onMouseLeave={() => setShowTooltip(false)}
			>
				<Icon size="small" fill={typeOfAnnotation === "siteImprove" ? ColorBlue : ""}>
					{icon}
				</Icon>
			</Button>
			<div
				className={cn(scss.annotationIconTooltip, "annotation-icon-tooltip")}
				style={{
					left: coordinates.left,
					top: coordinates.top + popoverTopOffset,
					visibility: showTooltip ? "visible" : "hidden",
				}}
			>
				<H6 className={scss.annotationIconTooltipHeader}>{content.header}</H6>
				<InlineText>{content.text}</InlineText>
			</div>
		</div>
	);
}
