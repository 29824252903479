/**
 * Checks if obj is of type 'object'
 */
function isObject(obj: unknown): obj is Record<string, unknown> {
	return !!obj && typeof obj === "object";
}

/**
 * Returns true if `obj` is an object which is not an array
 */
function isPlainObject<T>(obj: T): boolean {
	return isObject(obj) && !Array.isArray(obj);
}

/**
 * Merges 2 objects into 1
 * @param target object to be merged
 * @param source object to be merged
 * @returns a new object which 'target' and 'source' have been merged into
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mergeObjects<T extends Record<string, any>>(target: T, source: T): T {
	if (!isObject(target) || !isObject(source)) {
		return source;
	}

	for (const key in source) {
		const targetValue = target[key];
		const sourceValue = source[key];

		if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
			target[key] = targetValue.concat(sourceValue);
		} else if (isPlainObject(targetValue) && isPlainObject(sourceValue)) {
			target[key] = mergeObjects(Object.assign({}, targetValue), sourceValue);
		} else {
			target[key] = sourceValue;
		}
	}

	return target;
}
