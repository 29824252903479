import React from "react";
import Highcharts from "highcharts";
import { cn, f2u, InlineText } from "@siteimprove/fancylib";
import { useReRender } from "../../../utils/hooks";
import { Checkbox } from "../../forms-and-inputs/checkbox/checkbox";
import * as scss from "./chart-legend.scss";

export type ChartLegendProps = {
	chart: Highcharts.Chart;
	ariaLabelTemplate: string;
	onToggle?: (seriesIndex: number, visible: boolean) => void;
};

export function ChartLegend(props: ChartLegendProps): JSX.Element {
	const { chart, ariaLabelTemplate, onToggle } = props;
	return (
		<div className={cn(scss.legend, "fancy-ChartLegend")}>
			{chart.series
				.filter((series) => series.options.showInLegend !== false)
				.map((series: Highcharts.Series, index: number) => (
					<LegendItem
						series={series}
						key={series.name}
						ariaLabelTemplate={ariaLabelTemplate}
						onToggle={(visible) => onToggle?.(index, visible)}
					/>
				))}
		</div>
	);
}

type LegendItemProps = {
	series: Highcharts.Series;
	ariaLabelTemplate: string;
	onToggle?: (visible: boolean) => void;
};

function LegendItem(props: LegendItemProps): JSX.Element {
	const { series, ariaLabelTemplate, onToggle } = props;
	const chart = series.chart;
	const reRender = useReRender();
	const ariaLabel = ariaLabelTemplate.replace("{itemName}", series.name);
	return (
		<div className={cn(scss.legendItem, "fancy-ChartLegendItem")}>
			<Checkbox
				aria-label={ariaLabel}
				value={""}
				checked={series.visible}
				onChange={() => {
					const canToggle = chart.series.some((s) => (series === s ? !s.visible : s.visible));
					if (canToggle) {
						const newVisible = !series.visible;
						series.setVisible(newVisible);
						onToggle?.(newVisible);
					} else {
						series.select();
					}
					reRender.triggerRender();
				}}
			>
				{(opt: { checked: boolean; disabled: boolean }) => (
					<InlineText
						lineHeight="multi-line"
						emphasis={opt.checked ? "medium" : "normal"}
						tone={f2u(opt.disabled && "subtle")}
						size="xSmall"
					>
						{series.name}
					</InlineText>
				)}
			</Checkbox>
			<LegendItemMarker series={series} />
		</div>
	);
}

type LegendItemMarkerProps = {
	series: Highcharts.Series;
};

function LegendItemMarker(props: LegendItemMarkerProps): JSX.Element {
	const { series } = props;

	const chart = series.chart;
	const type = series.options.type;

	// The props below live in the series object, but are not
	// part of the type definition of Highcharts somehow
	/* eslint-disable @typescript-eslint/no-explicit-any */
	const color = (series as any).color;
	const symbol = (series as any).symbol || "circle";
	const dashStyle = (series as any).options.dashStyle;
	/* eslint-enable @typescript-eslint/no-explicit-any */

	const showLine = type === "line";
	const showMarkers = chart.options.plotOptions?.series?.marker?.enabled;
	const lineWidth = 4;
	const strokeDashArray = dashStyleToStrokeDashArray(dashStyle, lineWidth);

	return (
		<div className={scss.legendItemMarker}>
			<svg
				aria-hidden="true"
				focusable="false"
				width="100%"
				height="100%"
				viewBox="0 0 36 36"
				style={{
					fill: color,
					stroke: color,
					strokeWidth: lineWidth,
				}}
			>
				{showLine && <line x1="0" y1="18" x2="36" y2="18" strokeDasharray={strokeDashArray} />}
				{(showMarkers || !showLine) && <SvgSymbol symbol={symbol} />}
			</svg>
		</div>
	);
}

function SvgSymbol(props: {
	symbol: "circle" | "diamond" | "square" | "triangle" | "triangle-down";
}): JSX.Element {
	switch (props.symbol) {
		case "circle":
			return <circle cx="18" cy="18" r="9" />;
		case "diamond":
			return <polygon points="18,9 27,18 18,27 9,18" />;
		case "square":
			return <rect x="9" y="9" width="18" height="18" />;
		case "triangle":
			return <polygon points="18,9 27,27 9,27" />;
		case "triangle-down":
			return <polygon points="18,27 27,9 9,9" />;
	}
}

// copied from
// https://github.com/highcharts/highcharts/blob/470e382ea5b01313bf5010ef0ba37bbadb8f2859/ts/Core/Renderer/SVG/SVGElement.ts#L1155
function dashStyleToStrokeDashArray(
	dashStyle: Highcharts.DashStyleValue,
	strokeWidth: number
): string | undefined {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let value: any = dashStyle && dashStyle.toLowerCase();
	if (value) {
		value = value
			.replace("shortdashdotdot", "3,1,1,1,1,1,")
			.replace("shortdashdot", "3,1,1,1")
			.replace("shortdot", "1,1,")
			.replace("shortdash", "3,1,")
			.replace("longdash", "8,3,")
			.replace(/dot/g, "1,3,")
			.replace("dash", "4,3,")
			.replace(/,$/, "")
			.split(","); // ending comma

		let i = value.length;
		while (i--) {
			value[i] = parseInt(value[i]) * strokeWidth;
		}
		value = value.join(",").replace("NaN", "none");
		return value;
	}
}
