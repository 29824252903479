/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Highcharts from "highcharts";
import highchartsAccessibility from "highcharts/modules/accessibility";
import AnnotationsFactory from "highcharts/modules/annotations";
import highchartsExporting from "highcharts/modules/exporting";
import highchartsExportData from "highcharts/modules/export-data";
import highchartsPatternFill from "highcharts/modules/pattern-fill";
import {
	getDateTimeFormatInfo,
	getNumberFormatInfo,
	toAccessibilityDateString,
} from "../../../utils/locale";
import { mergeObjects } from "../../../utils/object-utils";
import { toArray } from "../../../utils/array-utils";
import { isNullOrUndefined } from "../../../utils/shorthands";
import { useDesignToken } from "../../context/theme/theme";
import {
	defaultChartAccessibilityOptions,
	defaultChartCreditsOptions,
	defaultChartExportingOptions,
	defaultChartLangOptions,
	defaultChartLegendOptions,
	defaultChartOptions,
	defaultChartSeriesOptions,
	defaultChartTitleOptions,
	defaultChartTooltipOptions,
	defaultChartXAxisOptions,
	defaultChartYAxisOptions,
} from "./chart-default-options";

type SeriesExt = Highcharts.Series & { prototype: unknown };

type HighchartsExt = {
	seriesTypes: Record<string, SeriesExt>;
};

export type TooltipHeaderFormatter = "default" | "named" | "keyed";
export type TooltipPointFormatter = "default" | "named" | "customValue";

export enum ChartSize {
	small = 210,
	medium = 310,
	large = 410,
}

export function highchartsCustomFunctions(highcharts: typeof Highcharts) {
	if (typeof highcharts !== "object") return;

	// init the accessibility module
	highchartsAccessibility(highcharts);

	// init the exporting module
	highchartsExporting(highcharts);

	// init the export data module
	highchartsExportData(highcharts);

	// init the pattern fill module
	highchartsPatternFill(highcharts);

	// Reflow charts on print - this doesn't happen automatically for some reason!
	reflowOnPrint(highcharts);

	// Reflow charts on event - charts does not reflow automatically when the container changes, so reflow can be called with an event
	reflowChartOnEvent(highcharts);

	// Initialize Annotations support of Highcharts
	AnnotationsFactory(highcharts);

	// Extend the column series' `drawLegendSymbol` which doesn't draw borders properly
	highcharts.wrap(
		(highcharts as unknown as HighchartsExt).seriesTypes["column"].prototype,
		"drawLegendSymbol",
		columnSeriesDrawLegendSymbolExtension
	);

	// Extend Highcharts to be able to correctly position the checkboxes and the legends
	highcharts.wrap(highcharts.Legend.prototype, "positionCheckboxes", legendPositionAdjustments);

	// Extend Highcharts to not change color of legend symbol when toggling visibility
	highcharts.wrap(
		highcharts.Legend.prototype,
		"colorizeItem",
		colorizeLegendRegardlessOfVisibility
	);

	// Add aria-atomic="true" to the elements with aria-live in the chart container
	highcharts.addEvent(highcharts.Chart, "render", addAriaAtomic);
}

function GetSeries(
	options: Highcharts.Options,
	predicate: ((serie: Highcharts.SeriesOptionsType) => boolean) | null
): Highcharts.SeriesOptionsType[] {
	const series = options.series && predicate ? options.series.filter(predicate) : options.series;
	return series || [];
}

type SharedAxisOptions = Pick<
	Highcharts.XAxisOptions | Highcharts.YAxisOptions,
	"id" | "max" | "tickInterval" | "tickPositions" | "tickPositioner" | "type"
>;

interface SeriesOptions {
	data: unknown[];
	type: string;
}

// This function makes the labels on the specified axis have a consistent spacing,
// by being aware of the amount of data points in the series,
// if and only if, no decision has already been made on how to place ticks for the axis
export function prepTickInterval(opts: Highcharts.Options, axisKey: "xAxis" | "yAxis") {
	const axisOpts:
		| Highcharts.XAxisOptions
		| Highcharts.YAxisOptions
		| Highcharts.XAxisOptions[]
		| Highcharts.YAxisOptions[]
		| undefined = opts[axisKey];
	if (axisOpts) {
		const arr = toArray<SharedAxisOptions>(axisOpts);
		arr.forEach((axis: SharedAxisOptions, i: number) => {
			if (axis === undefined) {
				return;
			}

			if (
				isNullOrUndefined(axis.tickInterval) &&
				isNullOrUndefined(axis.tickPositions) &&
				isNullOrUndefined(axis.tickPositioner)
			) {
				const series = GetSeries(
					opts,
					(serie) =>
						serie[axisKey] == axis.id ||
						serie[axisKey] == i ||
						(serie[axisKey] === undefined && i == 0)
				);

				if (axisKey == "yAxis") {
					let handleNoValues = true;

					series.forEach((serieAnon) => {
						const serie = serieAnon as SeriesOptions;
						if (serie.type == "line" || serie.type == "spline") {
							// For line/spline check if we need to force a max value on the yAxis to avoid weird looking chart
							if (axis.max == null) {
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								const hasNonZeroData = serie.data.some((x: any) => {
									if (typeof x === "object") {
										return x && x.y != null && x.y !== 0;
									} else {
										return x != null && x !== 0;
									}
								});

								if (hasNonZeroData) {
									handleNoValues = false;
								}
							}
						} else if (serie.data.length > 0) {
							handleNoValues = false;
						}
					});

					// Force max value as no valid value for yAxis has been found
					if (handleNoValues) {
						axis.max = 1;
					}
				} else {
					// For the xAxis get amount of data points
					const dataPoints = series.reduce((prev, serie) => {
						const data: unknown[] = (serie as SeriesOptions).data;
						return data && data.length > prev ? data.length : prev;
					}, 2);
					axis.tickInterval = Math.ceil(dataPoints < 5 ? dataPoints : dataPoints / 5);
				}
			}
		});
	}
}

export function prepareChartOptions(
	opts: Highcharts.Options,
	height: keyof typeof ChartSize | Highcharts.ChartOptions["height"],
	useHighchartsLegend: boolean | undefined,
	screenReaderRegionLabel: string,
	screenReaderChartHeading: string,
	defaultChartTitle: string,
	tableSummary: string,
	chartContainerLabel: string,
	legendItem: string,
	chartTypesMapTypeDescription: string,
	chartTypesCombinationChart: string,
	chartTypesDefaultSingle: string,
	chartTypesDefaultMultiple: string,
	chartTypesSplineSingle: string,
	chartTypesSplineMultiple: string,
	chartTypesLineSingle: string,
	chartTypesLineMultiple: string,
	chartTypesColumnSingle: string,
	chartTypesColumnMultiple: string,
	chartTypesBarSingle: string,
	chartTypesBarMultiple: string,
	chartTypesPieSingle: string,
	chartTypesPieMultiple: string,
	xAxisDescriptionSingular: string,
	xAxisDescriptionPlural: string,
	categoryColumnHeader: string,
	datetimeColumnHeader: string,
	yAxisDescriptionSingular: string,
	yAxisDescriptionPlural: string,
	chartSeriesDefault: string,
	chartSeriesDefaultCombination: string,
	chartSeriesLine: string,
	chartSeriesLineCombination: string,
	chartSeriesSpline: string,
	chartSeriesSplineCombination: string,
	chartSeriesColumn: string,
	chartSeriesColumnCombination: string,
	chartSeriesBar: string,
	chartSeriesBarCombination: string,
	chartSeriesPie: string,
	chartSeriesScatterCombination: string,
	chartSeriesMapCombination: string,
	chartSeriesMapbubbleCombination: string,
	xAxisDescription: string,
	yAxisDescription: string,
	a11yMode: boolean,
	locale: string,
	tooltipHeaderFormatter: TooltipHeaderFormatter | undefined,
	tooltipPointFormatter: TooltipPointFormatter | undefined,
	designTokens: ReturnType<typeof useDesignToken>
): Highcharts.Options {
	const altColorThreshold = 8;
	const defaultColors = [
		designTokens.ColorChart1,
		designTokens.ColorChart2,
		designTokens.ColorChart3,
		designTokens.ColorChart4,
		designTokens.ColorChart5,
		designTokens.ColorChart6,
		designTokens.ColorChart7,
		designTokens.ColorChart8,
	];
	const altColors = [
		designTokens.ColorChart1,
		designTokens.ColorChart2,
		designTokens.ColorChart3,
		designTokens.ColorChart4,
		designTokens.ColorChart5,
		designTokens.ColorChart6,
		designTokens.ColorChart7,
		designTokens.ColorChart8,
		designTokens.ColorChart1,
		designTokens.ColorChart2,
		designTokens.ColorChart3,
		designTokens.ColorChart4,
		designTokens.ColorChart5,
		designTokens.ColorChart6,
		designTokens.ColorChart7,
		designTokens.ColorChart8,
	];

	const series = GetSeries(opts, null);
	const chartColors = series.length > altColorThreshold ? altColors : defaultColors;

	const beforeChartFormatTemplate =
		"<h3>{chartTitle}</h3><p>{typeDescription}</p><p>{chartSubtitle}</p><p>{chartLongdesc}</p><p>{playAsSoundButton}</p><p>{xAxisDescription}</p><p>{yAxisDescription}</p><p>{annotationsTitle}{annotationsList}</p>";

	// Check if Series is supposed to be hidden from load (from the checkbox selected prop), if it is, hide it
	series.forEach((serie) => {
		if (serie.selected !== undefined && serie.selected === false) serie.visible = false;
		if (serie.visible === undefined) serie.visible = true;
	});

	const defaultOptions: Highcharts.Options = {
		chart: {
			height: height && height in ChartSize ? ChartSize[height as keyof typeof ChartSize] : height,
			...defaultChartOptions(designTokens),
		},
		title: defaultChartTitleOptions,
		colors: chartColors,
		legend: {
			...defaultChartLegendOptions,
			enabled: useHighchartsLegend,
		},
		xAxis: defaultChartXAxisOptions(designTokens),
		yAxis: defaultChartYAxisOptions(designTokens),
		tooltip: {
			...defaultChartTooltipOptions(designTokens),
			formatter: function () {
				return this.points?.reduce(
					function (label, point) {
						const pointColor =
							typeof point.color === "string" // simple color definition
								? point.color
								: typeof point.color === "object" && "pattern" in point.color // pattern fill definition
								? point.color.pattern.color
								: undefined;
						return `${label}<div><span style="color: ${pointColor};">●</span> ${
							point.series.name
						}: <span style="font-weight: bold;">${
							tooltipPointFormatter === "named"
								? point.point.name
								: tooltipPointFormatter === "customValue"
									? (point.point as any).customValue // eslint-disable-line
								: point.y // Default if tooltipPointFormatter is specified to be "default" or is not specified
						}</span></div>`;
					},
					`<div style="font-size: smaller"> ${
						this.points?.length > 0 &&
						this.x !== undefined &&
						// Format date if type is "datetime" and tooltipHeaderFormatter is not "named" or "keyed"
						this.points[0].series.xAxis.userOptions.type === "datetime" &&
						tooltipHeaderFormatter !== "named" &&
						tooltipHeaderFormatter !== "keyed"
							? toAccessibilityDateString(new Date(this.x), locale)
							: tooltipHeaderFormatter === "named"
							? this.points[0].point.name
							: tooltipHeaderFormatter === "keyed"
							? this.points[0].key
							: this.x // Default if tooltipPointFormatter is specified to be "default" or is not specified
					} </div>`
				);
			},
		},
		plotOptions: {
			series: {
				animation: true,
				showCheckbox: true,
				selected: true,
				events: {
					checkboxClick: function () {
						const shouldChangeCheckbox = this.chart.series.some((s) =>
							this === s ? !s.visible : s.visible
						);
						if (shouldChangeCheckbox) {
							this.setVisible(!this.visible);
						} else {
							this.select();
						}
					},
					legendItemClick: function () {
						const shouldChangeVisibility = this.chart.series.some((s) =>
							this === s ? !s.visible : s.visible
						);
						if (shouldChangeVisibility) {
							this.select();
						}
						return shouldChangeVisibility;
					},
				},
			},
			...defaultChartSeriesOptions(designTokens),
		},
		credits: defaultChartCreditsOptions,
		lang: defaultChartLangOptions(
			screenReaderRegionLabel,
			screenReaderChartHeading,
			defaultChartTitle,
			tableSummary,
			chartContainerLabel,
			legendItem,
			chartTypesMapTypeDescription,
			chartTypesCombinationChart,
			chartTypesDefaultSingle,
			chartTypesDefaultMultiple,
			chartTypesLineSingle,
			chartTypesLineMultiple,
			chartTypesBarSingle,
			chartTypesBarMultiple,
			chartTypesPieSingle,
			chartTypesPieMultiple,
			xAxisDescriptionSingular,
			xAxisDescriptionPlural,
			yAxisDescriptionSingular,
			yAxisDescriptionPlural,
			chartSeriesDefault,
			chartSeriesDefaultCombination,
			chartSeriesLine,
			chartSeriesLineCombination,
			chartSeriesColumn,
			chartSeriesColumnCombination,
			chartSeriesBar,
			chartSeriesBarCombination,
			chartSeriesPie,
			chartSeriesScatterCombination,
			chartSeriesMapCombination,
			chartSeriesMapbubbleCombination,
			xAxisDescription,
			yAxisDescription,
			datetimeColumnHeader,
			categoryColumnHeader
		),
		exporting: defaultChartExportingOptions,
		accessibility: defaultChartAccessibilityOptions(beforeChartFormatTemplate),
	};

	// toggle a11y mode
	toggleA11YEnhancedMode(a11yMode, series, defaultOptions, chartColors);

	// merge the default options with the options passed in
	const mergedOpts = mergeObjects(defaultOptions, opts);

	// avoid double legend
	if (mergedOpts.legend) {
		mergedOpts.legend.enabled = useHighchartsLegend;
	}

	return mergedOpts;
}

export function setGlobalOptions(highcharts: typeof Highcharts, formattingLang: string) {
	const numberFormatInfo = getNumberFormatInfo(formattingLang);
	const dateFormatInfo = getDateTimeFormatInfo(formattingLang);

	// check for SSR, see https://github.com/highcharts/highcharts-react/issues/76#issuecomment-446408078
	if (typeof highcharts === "object") {
		highcharts.setOptions({
			lang: {
				thousandsSep: numberFormatInfo.delimiters.thousands,
				decimalPoint: numberFormatInfo.delimiters.decimal,
				weekdays: dateFormatInfo.weekDays.long,
				shortWeekdays: dateFormatInfo.weekDays.short,
				months: dateFormatInfo.months.long,
				shortMonths: dateFormatInfo.months.short,
				accessibility: {
					// setting this to `null` has no effect and typescript complains
					thousandsSep: numberFormatInfo.delimiters.thousands,
				},
			},
		});
	}
}

type SeriesColumnOptionsExt = Highcharts.SeriesColumnOptions & { dashStyle: string | undefined };

function isSeries(item: Highcharts.Point | Highcharts.Series): item is Highcharts.Series {
	return (item as Highcharts.Series).removePoint !== undefined;
}

// Draw legend symbols for the column series properly
// the actual implementation of `drawLegendSymbol` in use (which we're extending) is `drawLineMarker`:
// https://github.com/highcharts/highcharts/blob/71d31ebc14172ec0de28912e54a7ae5f2cc2d7d0/ts/mixins/legend-symbol.ts#L111
export function columnSeriesDrawLegendSymbolExtension(
	this: Record<string, unknown>,
	proceed: (legend: Highcharts.Legend, item: Highcharts.Point | Highcharts.Series) => void,
	legend: Highcharts.Legend,
	item: Highcharts.Point | Highcharts.Series
) {
	proceed.call(this, legend, item);

	if (isSeries(item) && item.type === "column") {
		const {
			borderColor = "",
			borderWidth = 0,
			dashStyle = "",
		} = item.options as SeriesColumnOptionsExt;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(item as any).legendSymbol.attr({
			stroke: borderColor,
			"stroke-width": borderWidth,
			dashstyle: dashStyle,
		});
	}
}

// Adjust position of the Highchart Legend Checkbox, and switch label and symbol in the legend
export function legendPositionAdjustments(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	this: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	p: any,
	scrollOffset: number
) {
	const pixelsToREM = (value: number) => {
		return value / 16;
	};
	const alignAttr = this.group.alignAttr;
	const clipHeight = this.clipHeight || this.legendHeight;
	let translateY: number;

	const legendMainElement = this.box.parentGroup.element;
	// Adjust the main legend element to be closer to the checkbox
	if (legendMainElement) {
		Highcharts.attr(legendMainElement, "transform", "translate(19, 10)");
	}
	if (alignAttr) {
		translateY = alignAttr.translateY;
		this.allItems.forEach(function (item: {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			checkbox: any;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			legendItem: { getBBox: (arg0: boolean) => any };
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			checkboxOffset: any;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			legendGroup: any;
		}) {
			const bBox = item.legendItem.getBBox(true);

			// Change position of Label and Symbol in the Highcharts Legend
			const legendItemElement = item.legendGroup.element;
			const legendItemPath = legendItemElement.querySelector("path.highcharts-graph");
			const legendItemPoint = legendItemElement.querySelector("path.highcharts-point");
			const legendItemText = legendItemElement.querySelector("text");
			if (legendItemPath) {
				Highcharts.attr(legendItemPath, "transform", `translate(${bBox.width + 3}, 0)`);
			}
			if (legendItemPoint) {
				Highcharts.attr(legendItemPoint, "transform", `translate(${bBox.width + 3}, 0)`);
			}
			if (legendItemText) {
				Highcharts.attr(legendItemText, "x", 0);
			}

			// Adjust the position of the checkbox to the left side of the Highcharts Legend
			const checkbox = item.checkbox;
			let top;
			let left;
			if (checkbox) {
				top = translateY + checkbox.y + (scrollOffset || 0) + 4;
				left = alignAttr.translateX + item.checkboxOffset + checkbox.x - 100 - bBox.width + 17;
				Highcharts.css(checkbox, {
					left: pixelsToREM(left) + "rem",
					top: pixelsToREM(top) + "rem",
					display: top > translateY - 6 && top < translateY + clipHeight - 6 ? "" : "none",
				});
			}
		});
	}
}

// This function is called when triggering show/hide of series, always calling with visibility = true
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
export function colorizeLegendRegardlessOfVisibility(
	this: any,
	proceed: any,
	item: any,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	visible: any
): void {
	proceed.call(this, item, true);
}

//
// REFLOWING HIGHCHARTS CHART ON EVENT
//

export function reflowChartOnEvent(highcharts: typeof Highcharts): void {
	function reflowChart(index: number) {
		if (typeof highcharts.charts !== "undefined") {
			highcharts.charts[index]?.reflow();
		}
	}

	document.addEventListener("reflowHighchartsChart", ((event: CustomEvent<number>) => {
		reflowChart(event.detail);
	}) as EventListener);
}

//
// REFLOWING HIGHCHARTS ON PRINT
//

export function reflowOnPrint(highcharts: typeof Highcharts) {
	function reflowAllCharts() {
		if (typeof highcharts.charts !== "undefined") {
			highcharts.charts.forEach((chart: Highcharts.Chart | undefined) => {
				chart?.reflow();
			});
		}
	}

	if (window.matchMedia) {
		// Chrome and Safari (Firefox supports it but doesn't implement it the way we need)
		const mediaQueryList = window.matchMedia("print");
		mediaQueryList.addEventListener("change", reflowAllCharts);
	}

	window.addEventListener("beforeprint", reflowAllCharts);
}

// Add aria-atomic="true" to the elements with aria-live in the chart container
function addAriaAtomic(this: Highcharts.Chart) {
	if (!this.renderer || !this.renderer.box) return;
	const container = this.renderer.box.closest(".fancy-ChartContainer");
	const announcerContainer = container?.querySelector(".highcharts-announcer-container");
	const elements = announcerContainer?.querySelectorAll("[aria-live]") || [];
	elements.forEach((element) => {
		element.setAttribute("aria-atomic", "true");
	});
}

function toggleA11YEnhancedMode(
	a11yModeEnabled: boolean,
	mutableSeries: Highcharts.SeriesOptionsType[],
	mutableChartOptions: Highcharts.Options,
	defaultChartColors: string[]
): void {
	// enable markers for line charts when a11y mode is enabled
	mutableChartOptions.plotOptions = {
		...mutableChartOptions.plotOptions,
		series: {
			...mutableChartOptions.plotOptions?.series,
			marker: {
				enabled: a11yModeEnabled,
			},
		},
	};

	// enable pattern fill for bar charts when a11y mode is enabled
	mutableSeries.forEach((series, index) => {
		// backup the original color
		series.custom = series.custom || {};
		if (!("initialColor" in series.custom)) {
			series.custom = {
				...series.custom,
				initialColor: series.color,
			};
		}

		// skip if the series is not a bar chart nor a column chart
		if (series.type !== "bar" && series.type !== "column") return;

		// is a11y mode is disabled, restore the original color
		if (!a11yModeEnabled) {
			series.color = series.custom.initialColor;
			return;
		}

		// otherwise, set both the color and the pattern
		const fillColor =
			series.custom?.initialColor || defaultChartColors[index % defaultChartColors.length];
		const fillPattern = defaultFillPatterns[index % defaultFillPatterns.length];
		series.color = {
			pattern: {
				...fillPattern,
				color: fillColor,
			} as Highcharts.PatternOptionsObject,
		};
	});
}

// Default fill patterns for Highcharts, used for bar charts in a11y mode.
// We use the same patterns as the ones used in the Highcharts, but with
// different colors.
const defaultFillPatterns = [
	{
		path: "M 0 0 L 5 5 M 4.5 -0.5 L 5.5 0.5 M -0.5 4.5 L 0.5 5.5",
		width: 5,
		height: 5,
		patternTransform: "scale(1.4 1.4)",
	},
	{
		path: "M 0 5 L 5 0 M -0.5 0.5 L 0.5 -0.5 M 4.5 5.5 L 5.5 4.5",
		width: 5,
		height: 5,
		patternTransform: "scale(1.4 1.4)",
	},
	{
		path: "M 2 0 L 2 5 M 4 0 L 4 5",
		width: 5,
		height: 5,
		patternTransform: "scale(1.4 1.4)",
	},
	{
		path: "M 0 2 L 5 2 M 0 4 L 5 4",
		width: 5,
		height: 5,
		patternTransform: "scale(1.4 1.4)",
	},
	{
		path: "M 0 1.5 L 2.5 1.5 L 2.5 0 M 2.5 5 L 2.5 3.5 L 5 3.5",
		width: 5,
		height: 5,
		patternTransform: "scale(1.4 1.4)",
	},
	{
		path: "M 0 0 L 5 10 L 10 0",
		width: 10,
		height: 10,
	},
	{
		path: "M 3 3 L 8 3 L 8 8 L 3 8 Z",
		width: 10,
		height: 10,
	},
	{
		path: "M 5 5 m -4 0 a 4 4 0 1 1 8 0 a 4 4 0 1 1 -8 0",
		width: 10,
		height: 10,
	},
	{
		path: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
		width: 10,
		height: 10,
	},
	{
		path: "M 0 10 L 10 0 M -1 1 L 1 -1 M 9 11 L 11 9",
		width: 10,
		height: 10,
	},
];
