import * as React from "react";
import { DataObserveKey, SrOnly, VisualComponent, cn } from "@siteimprove/fancylib";
import {
	DateFormat,
	toAccessibilityDateString,
	toFormattedDateString,
} from "../../../utils/locale";
import { useLabTranslations } from "../../../translations/translations";
import * as scss from "./formatted-date.scss";

export type FormattedDateProps = {
	date: Date | null;
	locale: string;
	format?: DateFormat;
	skipTimezoneReset?: boolean;
} & VisualComponent &
	DataObserveKey;

export function FormattedDate(props: FormattedDateProps): JSX.Element {
	const { date, locale, format, skipTimezoneReset, className, ...rest } = props;
	const i18nLab = useLabTranslations();

	if (!date) {
		return (
			<span data-component="formatted-date" className={cn(scss.formattedDate, className)} {...rest}>
				<span aria-hidden="true">-</span>
				<SrOnly>{i18nLab.dateMissing}</SrOnly>
			</span>
		);
	}

	return (
		<time
			data-component="formatted-date"
			aria-label={toAccessibilityDateString(date, locale)}
			className={cn(scss.formattedDate, className)}
			{...rest}
		>
			{toFormattedDateString(date, locale, format, skipTimezoneReset)}
		</time>
	);
}
