import React from "react";
import { Button, Icon, SrOnly, Tooltip, TrueFalsy } from "@siteimprove/fancylib";
import { FormattedNumber } from "../../text/formatted-number/formatted-number";
import { TextHighlight } from "../../text/text-highlight/text-highlight";
import {
	BaseTableCellPosition,
	BasicHeaderConfig,
	ColumnConfig,
	ColumnConfigOptions,
	DtoToExpandedNode,
} from "../base-table/base-table";
import { FormattedDate } from "../../text/formatted-date/formatted-date";

export interface Percentage {
	percentageValue: number;
	total: number | null;
	value: number | null;
}

export function srOnlyHeader(label: string): BasicHeaderConfig {
	return { contentNode: <SrOnly>{label}</SrOnly> };
}

/***************************************
 *	PROPERTY-BASED COLUMN CONFIGS
 ***************************************/
export function colString<
	TKey extends Extract<keyof TDto, string>,
	TDto extends { [TProp in TKey]: string | null }
>(
	property: TKey,
	content?: string,
	tooltip?: string,
	query?: string,
	options: ColumnConfigOptions<TDto> = {},
	/** Used as a prefix (e.g. product name) for data observe keys */
	columnHeaderObserveKey?: string
): ColumnConfig<TDto> {
	return colMappedString(
		(dto) => dto[property],
		content,
		tooltip,
		property,
		query,
		options,
		columnHeaderObserveKey
	);
}

export function colMappedString<TKey extends Extract<keyof TDto, string>, TDto>(
	map: (dto: TDto) => string | null,
	content?: string,
	tooltip?: string,
	property?: TKey,
	query?: string,
	options: ColumnConfigOptions<TDto> = {},
	/** Used as a prefix (e.g. product name) for data observe keys */
	columnHeaderObserveKey?: string
): ColumnConfig<TDto> {
	return {
		header: {
			property,
			content,
			tooltip,
			defaultSortDirection: "asc",
			"data-observe-key": columnHeaderObserveKey,
		},
		options: {
			align: "left",
			...options,
		},
		render: (dto) => {
			const value = map(dto);
			return query ? <TextHighlight value={value} needle={query} /> : value;
		},
	};
}

export function colNumber<
	TKey extends Extract<keyof TDto, string>,
	TDto extends { [TProp in TKey]: number | null }
>(
	property: TKey,
	content?: string,
	tooltip?: string,
	options: ColumnConfigOptions<TDto> = {},
	/** Used as a prefix (e.g. product name) for data observe keys */
	columnHeaderObserveKey?: string
): ColumnConfig<TDto> {
	options.align = options.align || "right";
	return colMappedNumber(
		property,
		(dto) => dto[property],
		content,
		tooltip,
		options,
		undefined,
		columnHeaderObserveKey
	);
}

export function colMappedNumber<TKey extends Extract<keyof TDto, string>, TDto>(
	property: TKey,
	map: (dto: TDto) => number | null,
	content?: string,
	tooltip?: string,
	options: ColumnConfigOptions<TDto> = {},
	format: "number" | "percent" | undefined = undefined,
	/** Used as a prefix (e.g. product name) for data observe keys */
	columnHeaderObserveKey?: string
): ColumnConfig<TDto> {
	return {
		options,
		header: { property, content, tooltip, "data-observe-key": columnHeaderObserveKey },
		render: (dto) => {
			const value = map(dto);
			return value === null && options.cellEmptyState ? (
				options.cellEmptyState
			) : (
				<FormattedNumber number={value} format={format} />
			);
		},
	};
}

export function colMappedPercentage<
	TKey extends Extract<keyof TDto, string>,
	TDto extends { [_ in TKey]: Percentage | number | null }
>(
	property: TKey,
	map: (dto: TDto) => number | null,
	content?: string,
	tooltip?: string,
	options: ColumnConfigOptions<TDto> = {},
	/** Used as a prefix (e.g. product name) for data observe keys */
	columnHeaderObserveKey?: string
): ColumnConfig<TDto> {
	options.align = options.align || "right";
	return colMappedNumber(
		property,
		map,
		content,
		tooltip,
		options,
		"percent",
		columnHeaderObserveKey
	);
}

export function colDate<
	TKey extends Extract<keyof TDto, string>,
	TDto extends { [TProp in TKey]: Date | null }
>(
	property: TKey,
	locale: string,
	content?: string,
	options: ColumnConfigOptions<TDto> = {},
	skipTimezoneReset = false,
	/** Used as a prefix (e.g. product name) for data observe keys */
	columnHeaderObserveKey?: string
): ColumnConfig<TDto> {
	return {
		options,
		header: { property, content: content, "data-observe-key": columnHeaderObserveKey },
		render: (dto) => (
			<FormattedDate date={dto[property]} locale={locale} skipTimezoneReset={skipTimezoneReset} />
		),
	};
}

export function colDateTime<
	TKey extends Extract<keyof TDto, string>,
	TDto extends { [TProp in TKey]: Date | null }
>(
	property: TKey,
	locale: string,
	content?: string,
	options: ColumnConfigOptions<TDto> = {},
	/** Used as a prefix (e.g. product name) for data observe keys */
	columnHeaderObserveKey?: string,
	skipTimezoneReset = false
): ColumnConfig<TDto> {
	return {
		options,
		header: { property, content: content, "data-observe-key": columnHeaderObserveKey },
		render: (dto) => (
			<FormattedDate
				date={dto[property]}
				locale={locale}
				format="datetime"
				skipTimezoneReset={skipTimezoneReset}
			/>
		),
	};
}

export function colButton<TDto>(opts: {
	icon: JSX.Element;
	btnAriaLabel: string;
	action: (dto: TDto) => string;
	headerAriaLabel: string;
}): ColumnConfig<TDto> {
	return {
		options: {
			align: "center",
			cellPadding: "small",
			width: 1, // ends up being exactly content width + padding
		},
		header: srOnlyHeader(opts.headerAriaLabel),
		render: (dto: TDto) => (
			<Button onClick={() => opts.action(dto)} aria-label={opts.btnAriaLabel}>
				<Icon>{opts.icon}</Icon>
			</Button>
		),
	};
}

export function colButtonLink<TDto>(opts: {
	icon: JSX.Element;
	btnAriaLabel: string;
	hrefFromDto: (dto: TDto) => string;
	headerAriaLabel: string;
	openNew?: boolean;
	/** This tooltip will not be part of the header, but be wrapping the buttons in the column as an interactive tooltip */
	tooltip?: string;
}): ColumnConfig<TDto> {
	return {
		options: {
			align: "center",
			cellPadding: "small",
			width: 1, // ends up being exactly content width + padding
		},
		header: srOnlyHeader(opts.headerAriaLabel),
		render: (dto: TDto) => {
			const btn = (
				<Button href={opts.hrefFromDto(dto)} aria-label={opts.btnAriaLabel} openNew={opts.openNew}>
					<Icon>{opts.icon}</Icon>
				</Button>
			);
			return opts.tooltip ? (
				<Tooltip variant={{ type: "interactive" }} content={opts.tooltip}>
					{btn}
				</Tooltip>
			) : (
				btn
			);
		},
	};
}

/***************************************
 *	COMPOSITE COLUMN CONFIGS
 ***************************************/

export function colExpandable<TDto>(
	columnConfig: ColumnConfig<TDto>,
	predicate: (item: TDto, cellPosition: BaseTableCellPosition) => TrueFalsy,
	renderer: DtoToExpandedNode<TDto>
): ColumnConfig<TDto> {
	return {
		...columnConfig,
		expandOptions: {
			canCellExpand: predicate,
			cellExpandRenderer: renderer,
		},
	};
}
