import React, { useEffect, useState } from "react";
import { Paragraph } from "@siteimprove/fancylib";
import { Example, Header, Code } from "../../../../../src/docs";
import { CodeBlock } from "../../../../../src/docs/docs-code";
import { Chart } from "./chart";
import {
	AnnotationAreasVisibleIn,
	AnnotationItem,
	AnnotationVisibility,
	DateOptions,
} from "./annotations/annotations";

export default (): JSX.Element => (
	<>
		<br />
		<Header.H3>Basic usage</Header.H3>
		<Example fn={LineChartBasicUsage} />
		<Header.H3>Usage with categories</Header.H3>
		<Example fn={LineChartUsageWithCategories} />
		<Header.H3>Usage with X axis showing time</Header.H3>
		<Example fn={LineChartUsageWithDateTimeOnXAxis} />
		<Header.H3>Usage with multiple Y axes</Header.H3>
		<Example fn={LineChartUsageWithMultipleYAxes} />
		<Header.H3>Usage with several series</Header.H3>
		<Example fn={LineChartUsageWithSeveralSeries} />
		<Header.H3>Usage with Annotations</Header.H3>
		<Paragraph>
			Annotations are designed to be easily adaptable to any backend. For that, you can map your own
			data structure to our Annotations interface which has the following definition:
		</Paragraph>
		<CodeBlock language="tsx">
			{`type AnnotationItem = {
	id: string | number;
	date: Date;
	comment: string;
	createdBy: string;
	visibility: string;
	isFavorite: boolean;
	visibleIn: string[];
	username: string;
}`}
		</CodeBlock>
		<Paragraph>
			In addition, the <Code>Chart</Code> component also provides properties for handling all basic
			annotation operations, such as creation (<Code>onCreateAnnotation</Code>), update (
			<Code>onUpdateAnnotation</Code>), and deletion (<Code>onDeleteAnnotation</Code>).
		</Paragraph>
		<Paragraph>
			In the example below, the <Code>API</Code> object simulates the implementation of these
			methods and also provides a list of annotations according to the <Code>AnnotationItem</Code>{" "}
			interface.
		</Paragraph>
		<Example fn={LineChartAnnotationsUsage} />
	</>
);

/** @ignore [visual] [a11y:R54] */
const LineChartBasicUsage = () => (
	<Chart
		options={{
			series: [
				{
					type: "line",
					name: "Series 1",
					data: [25, 23, 48, 52, 52, 57, 56, 61, 66].map((val, i) => {
						return {
							x: i,
							y: val,
							name: i.toString(),
							customValue: "customValue1",
						};
					}),
				},
				{
					type: "line",
					name: "Series 2",
					data: [18, 26, 36, 44, 49, 59, 65, 71, 82].map((val, i) => {
						return {
							x: i,
							y: val,
							name: i.toString(),
							customValue: "customValue2",
						};
					}),
				},
			],
		}}
		{...translations}
	/>
);

/** @ignore [visual] [a11y:R54] */
const LineChartUsageWithCategories = () => (
	<Chart
		options={{
			xAxis: {
				categories: ["Apples", "Bananas", "Oranges", "Strawberries"],
				tickInterval: 1,
			},
			series: [
				{
					type: "line",
					name: "Series 1",
					data: [25, 23, 48, 52],
				},
				{
					type: "line",
					name: "Series 2",
					data: [18, 26, 36, 44],
				},
			],
		}}
		{...translations}
	/>
);

/** @ignore [visual] [a11y:R54] */
const LineChartUsageWithDateTimeOnXAxis = () => (
	<Chart
		options={{
			xAxis: {
				type: "datetime",
				// Set interval of tick marks to one month. For datetime axes this is based on milliseconds.
				tickInterval: 24 * 3600 * 1000 * 30,
			},
			series: [
				{
					type: "line",
					name: "Series 1",
					dashStyle: "ShortDot",
					data: [
						{
							x: Date.UTC(2010, 0, 1),
							y: 10,
						},
						{
							x: Date.UTC(2010, 1, 2),
							y: 20,
						},
						{
							x: Date.UTC(2010, 2, 3),
							y: 30,
						},
						{
							x: Date.UTC(2010, 3, 4),
							y: 40,
						},
						{
							x: Date.UTC(2010, 4, 5),
							y: 50,
						},
					],
				},
				{
					type: "line",
					name: "Series 2",
					data: [
						{
							x: Date.UTC(2010, 0, 1),
							y: 20,
						},
						{
							x: Date.UTC(2010, 1, 2),
							y: 30,
						},
						{
							x: Date.UTC(2010, 2, 3),
							y: 40,
						},
						{
							x: Date.UTC(2010, 3, 4),
							y: 50,
						},
						{
							x: Date.UTC(2010, 5, 5),
							y: 60,
						},
					],
				},
			],
		}}
		{...translations}
	/>
);

/** @ignore [visual] [a11y:R54] */
const LineChartUsageWithMultipleYAxes = () => (
	<Chart
		options={{
			xAxis: [
				{
					type: "datetime",
					// Set interval of tick marks to one month. For datetime axes this is based on milliseconds.
					tickInterval: 24 * 3600 * 1000 * 30,
				},
			],
			yAxis: [{}, { opposite: true }],
			series: [
				{
					type: "line",
					name: "Series 1",
					data: [
						{
							x: Date.UTC(2010, 0, 1),
							y: 10,
						},
						{
							x: Date.UTC(2010, 1, 1),
							y: 20,
						},
						{
							x: Date.UTC(2010, 2, 1),
							y: 30,
						},
						{
							x: Date.UTC(2010, 3, 1),
							y: 40,
						},
						{
							x: Date.UTC(2010, 4, 1),
							y: 50,
						},
					],
				},
				{
					type: "line",
					name: "Series 2",
					data: [
						{
							x: Date.UTC(2010, 0, 1),
							y: 20,
						},
						{
							x: Date.UTC(2010, 1, 1),
							y: 30,
						},
						{
							x: Date.UTC(2010, 2, 1),
							y: 40,
						},
						{
							x: Date.UTC(2010, 3, 1),
							y: 50,
						},
						{
							x: Date.UTC(2010, 5, 1),
							y: 60,
						},
					],
				},
				{
					type: "line",
					name: "Series 3",
					data: [
						{
							x: Date.UTC(2010, 0, 1),
							y: 25,
						},
						{
							x: Date.UTC(2010, 1, 1),
							y: 50,
						},
						{
							x: Date.UTC(2010, 2, 1),
							y: 75,
						},
						{
							x: Date.UTC(2010, 3, 1),
							y: 90,
						},
						{
							x: Date.UTC(2010, 5, 1),
							y: 105,
						},
					],
					yAxis: 1,
				},
			],
		}}
		{...translations}
	/>
);

/** @ignore [visual] [a11y:R54] */
const LineChartUsageWithSeveralSeries = () => (
	<Chart
		options={{
			series: [
				{
					type: "line",
					name: "Series 1",
					data: [1.12, 1.14, 1.13, 1.16, 1.18, 1.17, 1.15, 1.19, 1.2, 1.22].map((val, i) => {
						return {
							x: i,
							y: val,
							name: i.toString(),
						};
					}),
				},
				{
					type: "line",
					name: "Series 2",
					data: [1.01, 1.05, 1.1, 1.09, 1.05, 1.02, 1.0, 1.01, 1.01, 1.02].map((val, i) => {
						return {
							x: i,
							y: val,
							name: i.toString(),
						};
					}),
				},
				{
					type: "line",
					name: "Series 3",
					data: [0.9, 1.01, 1.02, 1.03, 1.02, 1.0, 0.98, 0.96, 0.95, 0.99].map((val, i) => {
						return {
							x: i,
							y: val,
							name: i.toString(),
						};
					}),
				},
				{
					type: "line",
					name: "Series 4",
					data: [1.5, 1.6, 1.65, 1.7, 1.75, 1.78, 1.78, 1.79, 1.8, 1.8].map((val, i) => {
						return {
							x: i,
							y: val,
							name: i.toString(),
						};
					}),
				},
				{
					type: "line",
					name: "Series 5",
					data: [1.4, 1.38, 1.35, 1.31, 1.3, 1.3, 1.29, 1.28, 1.3, 1.32].map((val, i) => {
						return {
							x: i,
							y: val,
							name: i.toString(),
						};
					}),
				},
				{
					type: "line",
					name: "Series 6",
					data: [1.0, 1.1, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2.0].map((val, i) => {
						return {
							x: i,
							y: val,
							name: i.toString(),
						};
					}),
				},
			],
		}}
		{...translations}
	/>
);

// Annotations control
const API = {
	data: [
		{
			id: 1,
			date: new Date(2022, 0, 1),
			comment:
				"Pages are now only flagged if the number of internal links exceeds 300. This means you will probably see a slight increase in your DCI and SEO Scores.",
			createdBy: "Alvaro",
			visibility: AnnotationVisibility.ONLY_ME,
			isFavorite: false,
			visibleIn: ["accessibility", "ads"],
		},
		{
			id: 2,
			date: new Date(2022, 1, 1),
			comment:
				"Pages are now only flagged if they contain less than 250 words (previously it was 500 words). This means you should see a slight increase in your DCI and SEO Scores.",
			createdBy: "Alvaro",
			visibility: AnnotationVisibility.EVERYONE,
			isFavorite: false,
			visibleIn: ["analytics"],
			isSystemNotification: true,
		},
		{
			id: 3,
			date: new Date(2022, 2, 15),
			comment:
				"We've improved how pages with rel=canonical tags are checked. As a result, you may notice an increase in your SEO and DCI Scores.",
			createdBy: "Alvaro",
			visibility: AnnotationVisibility.ONLY_ME,
			isFavorite: false,
			visibleIn: ["seo"],
		},
		{
			id: 4,
			date: new Date(2022, 3, 1),
			comment: "Outlier point due to the increase in sales.",
			createdBy: "Alvaro",
			visibility: AnnotationVisibility.ONLY_ME,
			isFavorite: true,
			visibleIn: ["data-privacy"],
		},
		{
			id: 15,
			date: new Date(2022, 3, 1),
			comment:
				"Switched back to the old Accessibility experience. You may see a change in your DCI score due to differences in accessibility testing standards. ",
			createdBy: "Alvaro",
			visibility: AnnotationVisibility.ONLY_ME,
			isFavorite: true,
			visibleIn: ["data-privacy"],
		},
		{
			id: 5,
			date: new Date(2022, 4, 1),
			comment:
				"Upgraded to Accessibility NextGen. You may see a change in your DCI score due to new and improved accessibility testing standards.",
			createdBy: "Alvaro",
			visibility: AnnotationVisibility.ONLY_ME,
			isFavorite: true,
			visibleIn: ["ads"],
		},
		{
			id: 6,
			date: new Date(2022, 5, 1),
			comment:
				"If your site is mobile-friendly, images that are missing height and width attributes will no longer be flagged as an issue, as images will resize according to the screen size. This means you might see a slight increase in your DCI and SEO Scores.",
			createdBy: "Alvaro",
			visibility: AnnotationVisibility.ONLY_ME,
			isFavorite: false,
			visibleIn: ["quality-assurance"],
		},
		{
			id: 7,
			date: new Date(2022, 6, 1),
			comment:
				"We've updated our check for structured data markup so that it now detects three of the most commonly used structured data markup implementations. This means you might see a small increase in your DCI Score.",
			createdBy: "Alvaro",
			visibility: AnnotationVisibility.ONLY_ME,
			isFavorite: true,
			visibleIn: ["accessibility", "ads", "analytics", "data-privacy", "seo", "quality-assurance"],
		},
		{
			id: 8,
			date: new Date(2022, 7, 1),
			comment:
				"CSS and HTML validation issues have been removed. As a result, you might see a small change in your DCI Score.",
			createdBy: "Alvaro",
			visibility: AnnotationVisibility.ONLY_ME,
			isFavorite: false,
			visibleIn: [],
		},
		{
			id: 9,
			date: new Date(2022, 8, 1),
			comment:
				"We've added five new SEO checks relating to meta descriptions and meta titles. As a result, you might see a change in your SEO and DCI Scores.",
			createdBy: "Alvaro",
			visibility: AnnotationVisibility.ONLY_ME,
			isFavorite: false,
			visibleIn: [],
		},
		{
			id: 10,
			date: new Date(2022, 9, 1),
			comment:
				"The desktop speed and mobile speed checks have been updated to reflect changes in Google's PageSpeed Insights tool. This means you might see a small change in your SEO and DCI Scores.",
			createdBy: "Alvaro",
			visibility: AnnotationVisibility.ONLY_ME,
			isFavorite: false,
			visibleIn: [],
		},
	] as AnnotationItem[],
	areasVisibleIn: [
		{
			label: "Accessibility",
			value: "accessibility",
		},
		{
			label: "ADS",
			value: "ads",
		},
		{
			label: "Analytics",
			value: "analytics",
		},
		{
			label: "Data Privacy",
			value: "data-privacy",
		},
		{
			label: "SEO",
			value: "seo",
		},
		{
			label: "Quality Assurance",
			value: "quality-assurance",
		},
	],
	fetch: async (): Promise<AnnotationItem[]> => {
		await sleep(1000);
		return [...API.data];
	},
	fetchAreasVisibleIn: async (): Promise<AnnotationAreasVisibleIn[]> => {
		await sleep(1000);
		return API.areasVisibleIn;
	},
	update: async (data: Omit<Partial<AnnotationItem>, "createdBy">): Promise<void> => {
		// Simulate backend UPDATE call
		await sleep(1000);
		const annotationIndex = API.data.findIndex((annotation) => annotation.id === data.id);

		if (annotationIndex === -1) return;

		API.data[annotationIndex] = { ...API.data[annotationIndex], ...data };
	},
	create: async (data: Omit<AnnotationItem, "id" | "createdBy">): Promise<void> => {
		// Simulate backend CREATE call
		await sleep(1000);
		const username = await API.getUser();
		const newId = (API.data[API.data.length - 1].id as number) + 1;
		const newAnnotation: AnnotationItem = { ...data, id: newId, createdBy: username };
		API.data.push(newAnnotation);
	},
	delete: async (id: number | string): Promise<void> => {
		// Simulate backend DELETE call
		await sleep(1000);
		const annotationIndex = API.data.findIndex((annotation) => annotation.id === id);

		if (annotationIndex === -1) return;

		API.data.splice(annotationIndex, 1);
	},
	getUser: async (): Promise<string> => {
		// Simulate backend call that would return the username of current user
		await sleep(1000);
		const username = "Alvaro Silva";
		return username;
	},
};

const sleep = (n: number) => {
	return new Promise((resolve) => setTimeout(resolve, n));
};

/** @ignore [visual] [a11y:R54] */
const LineChartAnnotationsUsage = () => {
	const chartDateOptions: DateOptions = {
		locale: "en-us",
		skipTimezoneReset: false,
	};

	const [annotationsData, setAnnotationsData] = useState<AnnotationItem[]>([]);
	const [annotationsAreasVisibleIn, setAnnotationsAreasVisibleIn] = useState<
		AnnotationAreasVisibleIn[]
	>([]);

	useEffect(() => {
		let isDataFetchDone = true;
		API.fetch().then((data) => isDataFetchDone && setAnnotationsData(data));
		API.fetchAreasVisibleIn().then(
			(areas) => isDataFetchDone && setAnnotationsAreasVisibleIn(areas)
		);
		return () => {
			isDataFetchDone = false;
		};
	}, []);

	return (
		<Chart
			options={{
				xAxis: {
					type: "datetime",
					// Set interval of tick marks to one month. For datetime axes this is based on milliseconds.
					tickInterval: 24 * 3600 * 1000 * 30,
				},
				series: [
					{
						type: "line",
						name: "Series 1",
						data: [
							{
								x: Date.UTC(2022, 1, 1),
								y: 20,
							},
							{
								x: Date.UTC(2022, 2, 1),
								y: 30,
							},
							{
								x: Date.UTC(2022, 3, 1),
								y: 40,
							},
							{
								x: Date.UTC(2022, 4, 1),
								y: 50,
							},
							{
								x: Date.UTC(2022, 5, 1),
								y: 30,
							},
						],
					},
					{
						type: "line",
						name: "Series 2",
						data: [
							{
								x: Date.UTC(2022, 0, 1),
								y: 20,
							},
							{
								x: Date.UTC(2022, 1, 1),
								y: 30,
							},
							{
								x: Date.UTC(2022, 2, 1),
								y: 40,
							},
							{
								x: Date.UTC(2022, 3, 1),
								y: 50,
							},
							{
								x: Date.UTC(2022, 5, 1),
								y: 60,
							},
						],
					},
				],
			}}
			{...translations}
			showAnnotationButton
			annotations={annotationsData}
			chartPeriod={[new Date(2022, 0, 1), new Date(2022, 3, 1)]}
			onUpdateAnnotation={async (data: Omit<Partial<AnnotationItem>, "createdBy">) => {
				await API.update(data);
				setAnnotationsData(await API.fetch());
			}}
			onCreateAnnotation={async (data: Omit<AnnotationItem, "id" | "createdBy">) => {
				await API.create(data);
				setAnnotationsData(await API.fetch());
			}}
			onDeleteAnnotation={async (id: string | number) => {
				await API.delete(id);
				setAnnotationsData(await API.fetch());
			}}
			chartDateOptions={chartDateOptions}
			annotationAreasVisibleIn={annotationsAreasVisibleIn}
		/>
	);
};

const translations = {
	screenReaderRegionLabel: "Chart",
	screenReaderChartHeading: "Chart graphic",
	defaultChartTitle: "Chart title",
	tableSummary: "Table representation of chart.",
	chartContainerLabel: "{title}. Use up and down arrows to navigate with most screen readers.",
	legendItem: "Enable series: {itemName}",
	chartTypesMapTypeDescription: "Map of {mapTitle} with {numSeries} data series.",
	chartTypesCombinationChart: "Combination chart with {numSeries} data series.",
	chartTypesDefaultSingle: "Chart with {numPoints} data {#plural(numPoints, points, point)}.",
	chartTypesDefaultMultiple: "Chart with {numSeries} data series.",
	chartTypesSplineSingle: "Line chart with {numPoints} data {#plural(numPoints, points, point)}.",
	chartTypesSplineMultiple: "Line chart with {numSeries} lines.",
	chartTypesLineSingle: "Line chart with {numPoints} data {#plural(numPoints, points, point)}.",
	chartTypesLineMultiple: "Line chart with {numSeries} lines.",
	chartTypesColumnSingle: "Bar chart with {numPoints} {#plural(numPoints, bars, bar)}.",
	chartTypesColumnMultiple: "Bar chart with {numSeries} data series.",
	chartTypesBarSingle: "Bar chart with {numPoints} {#plural(numPoints, bars, bar)}.",
	chartTypesBarMultiple: "Bar chart with {numSeries} data series.",
	chartTypesPieSingle: "Pie chart with {numPoints} {#plural(numPoints, slices, slice)}.",
	chartTypesPieMultiple: "Pie chart with {numSeries} pies.",
	xAxisDescriptionSingular: "X axis that shows {names[0]}.",
	xAxisDescriptionPlural:
		"The chart has {numAxes} X axes that show {#each(names, -1) }and {names[-1]}",
	categoryColumnHeader: "Category",
	datetimeColumnHeader: "Date and/or time",
	yAxisDescriptionSingular: "Y axis that shows {names[0]}.",
	yAxisDescriptionPlural:
		"The chart has {numAxes} Y axes that show {#each(names, -1) }and {names[-1]}",
	chartSeriesDefault:
		"{name}, series {ix} of {numSeries} with {numPoints} data {#plural(numPoints, points, point)}.",
	chartSeriesDefaultCombination:
		"{name}, series {ix} of {numSeries} with {numPoints} data {#plural(numPoints, points, point)}.",
	chartSeriesLine:
		"{name}, line {ix} of {numSeries} with {numPoints} data {#plural(numPoints, points, point)}.",
	chartSeriesLineCombination:
		"{name}, series {ix} of {numSeries}. Line with {numPoints} data {#plural(numPoints, points, point)}.",
	chartSeriesSpline:
		"{name}, line {ix} of {numSeries} with {numPoints} data {#plural(numPoints, points, point)}.",
	chartSeriesSplineCombination:
		"{name}, series {ix} of {numSeries}. Line with {numPoints} data {#plural(numPoints, points, point)}.",
	chartSeriesColumn:
		"{name}, bar series {ix} of {numSeries} with {numPoints} {#plural(numPoints, bars, bar)}.",
	chartSeriesColumnCombination:
		"{name}, series {ix} of {numSeries}. Bar series with {numPoints} {#plural(numPoints, bars, bar)}.",
	chartSeriesBar:
		"{name}, bar series {ix} of {numSeries} with {numPoints} {#plural(numPoints, bars, bar)}.",
	chartSeriesBarCombination:
		"{name}, series {ix} of {numSeries}. Bar series with {numPoints} {#plural(numPoints, bars, bar)}.",
	chartSeriesPie:
		"{name}, pie {ix} of {numSeries} with {numPoints} {#plural(numPoints, slices, slice)}.",
	chartSeriesScatterCombination:
		"{name}, series {ix} of {numSeries}, scatter plot with {numPoints} {#plural(numPoints, points, point)}.",
	chartSeriesMapCombination:
		"{name}, series {ix} of {numSeries}. Map with {numPoints} {#plural(numPoints, areas, area)}.",
	chartSeriesMapbubbleCombination:
		"{name}, series {ix} of {numSeries}. Bubble series with {numPoints} {#plural(numPoints, bubbles, bubble)}.",
	xAxisDescription: "X axis, {name}",
	yAxisDescription: "Y axis, {name}",
	contextMenuForLabel: "Context menu for",
	contextMenuEnhancedContrastLabel: "Enhanced contrast",
	contextMenuEnhancedContrastTooltip:
		"Makes series more clear by introducing patterns and other visual aids.",
	contextMenuTableViewLabel: "Open table data view",
	contextMenuTableViewTooltip: "The table data view allows you to view the data in a table format.",
};
