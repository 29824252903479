import React, { useState } from "react";
import { Link, Paragraph, Tabs, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
	Knobs,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { Chart } from "./chart";
import LineChartExamples from "./line-chart.examples.part";
import ColumnChartExamples from "./column-chart.examples.part";
import MiscExamples from "./misc.examples.part";

export const Meta: DocPageMeta = {
	category: "Data Visualization",
	title: "Chart",
	notepad: "https://hackmd.io/AH46UmycRPGaRo72uuC7pg",
};

export default (): JSX.Element => {
	const [selectedTab, setSelectedTab] = useState(0);
	return (
		<>
			<HeaderSection
				title="Chart"
				subTitle="Present users with interactive graphical representations of data."
			/>
			<ContentSection>
				<TextContainer article>
					<LabWarning />
					<ImportExample lab component={Chart} />
					<Header.H2>Intro</Header.H2>
					<Paragraph>
						The <Code>Chart</Code> component is a tailored wrapper built for the{" "}
						<Link inlineInText href="https://www.highcharts.com">
							Highcharts
						</Link>{" "}
						library within a React environment. It's designed to offer a seamless integration of
						Highcharts while incorporating customized features, improved accessibility, and enhanced
						usability.
					</Paragraph>
					<Header.H3>Key Features</Header.H3>
					<Ul
						items={[
							<>
								<b>Customizations:</b> The <Code>Chart</Code> component provides predefined
								customizations regarding colors, sizes, and themes to maintain consistency and
								visual appeal across charts.
							</>,
							<>
								<b>Accessibility Improvements:</b> Accessibility is a prime focus. The component
								ensures that charts are optimized for screen readers and other assistive
								technologies, making data visualization more inclusive.
							</>,
							<>
								<b>Enhanced Usability:</b> With improved interaction elements and intuitive
								functionalities, the <Code>Chart</Code> component aims to enhance user experience
								and ease of navigation within the charts.
							</>,
							<>
								<b>Highcharts Features:</b> Developers have full access to all Highcharts features,
								allowing them to leverage the extensive capabilities of Highcharts to create
								sophisticated and detailed charts.
							</>,
						]}
					/>
					<Header.H3>Support and Troubleshooting</Header.H3>
					<Paragraph>
						The <Code>Chart</Code> component strives to ensure a seamless experience with Highcharts
						integrations. If you encounter any issues or discrepancies specific to the{" "}
						<Code>Chart</Code> component that differ from the standard Highcharts behavior, please
						reach out to us for support. If the issue works as expected with Highcharts in its raw
						form, kindly let us know to address the compatibility concern.
					</Paragraph>
					<Header.H2>Examples</Header.H2>
					<Paragraph>
						The examples below showcase the various kinds of charts suported by Highcharts, such as
						line charts, column charts, and more (coming soon). Click on the tabs to view the
						examples.
					</Paragraph>
					<Paragraph>
						For general features and functionalities, please refer to the{" "}
						<a href="#miscellaneous">Miscellaneous</a> section.
					</Paragraph>
					<br />
					<Tabs
						selectedTab={selectedTab}
						onChange={setSelectedTab}
						tabs={[
							{
								header: "Line chart",
								content: <LineChartExamples />,
							},
							{
								header: "Column chart",
								content: <ColumnChartExamples />,
							},
						]}
					/>
					<Header.H2>Miscellaneous</Header.H2>
					<Paragraph>
						These examples showcase the various features and functionalities of the{" "}
						<Code>Chart</Code> component that are not specific to any particular chart type. It
						includes features such as legends, tooltips, sizes, and more.
					</Paragraph>
					<MiscExamples />
					<Header.H2>Properties</Header.H2>
					<Knobs
						component={Chart}
						initialProps={{
							options: {
								series: [
									{
										type: "line",
										name: "Series 1",
										data: [25, 23, 48, 52, 52, 57, 56, 61, 66].map((val, i) => {
											return {
												x: i,
												y: val,
												name: val.toString(),
											};
										}),
									},
								],
							},
							...translations,
						}}
					/>
					<Header.H2>Guidelines</Header.H2>
					<Header.H3>Best practices</Header.H3>
					<InlineMessage variant="best-practices">
						<Ul items={["...", "..."]} />
					</InlineMessage>
					<Header.H3>Do not use when</Header.H3>
					<InlineMessage variant="do-not-use-when">
						<Ul items={["...", "..."]} />
					</InlineMessage>
					<Header.H3>Accessibility</Header.H3>
					<InlineMessage variant="accessibility">
						<Paragraph>
							This component comes with built-in accessibility, no extra work required.
						</Paragraph>
					</InlineMessage>
					<Paragraph>
						Explore detailed guidelines for this component:{" "}
						<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
							Accessibility Specifications
						</a>
					</Paragraph>
					<Header.H3>Writing</Header.H3>
					<InlineMessage variant="writing">
						<Ul items={["...", "..."]} />
					</InlineMessage>
				</TextContainer>
			</ContentSection>
		</>
	);
};

const translations = {
	screenReaderRegionLabel: "Chart",
	screenReaderChartHeading: "Chart graphic",
	defaultChartTitle: "Chart title",
	tableSummary: "Table representation of chart.",
	chartContainerLabel: "{title}. Use up and down arrows to navigate with most screen readers.",
	legendItem: "Enable series: {itemName}",
	chartTypesMapTypeDescription: "Map of {mapTitle} with {numSeries} data series.",
	chartTypesCombinationChart: "Combination chart with {numSeries} data series.",
	chartTypesDefaultSingle: "Chart with {numPoints} data {#plural(numPoints, points, point)}.",
	chartTypesDefaultMultiple: "Chart with {numSeries} data series.",
	chartTypesSplineSingle: "Line chart with {numPoints} data {#plural(numPoints, points, point)}.",
	chartTypesSplineMultiple: "Line chart with {numSeries} lines.",
	chartTypesLineSingle: "Line chart with {numPoints} data {#plural(numPoints, points, point)}.",
	chartTypesLineMultiple: "Line chart with {numSeries} lines.",
	chartTypesColumnSingle: "Bar chart with {numPoints} {#plural(numPoints, bars, bar)}.",
	chartTypesColumnMultiple: "Bar chart with {numSeries} data series.",
	chartTypesBarSingle: "Bar chart with {numPoints} {#plural(numPoints, bars, bar)}.",
	chartTypesBarMultiple: "Bar chart with {numSeries} data series.",
	chartTypesPieSingle: "Pie chart with {numPoints} {#plural(numPoints, slices, slice)}.",
	chartTypesPieMultiple: "Pie chart with {numSeries} pies.",
	xAxisDescriptionSingular: "X axis that shows {names[0]}.",
	xAxisDescriptionPlural:
		"The chart has {numAxes} X axes that show {#each(names, -1) }and {names[-1]}",
	categoryColumnHeader: "Category",
	datetimeColumnHeader: "Date and/or time",
	yAxisDescriptionSingular: "Y axis that shows {names[0]}.",
	yAxisDescriptionPlural:
		"The chart has {numAxes} Y axes that show {#each(names, -1) }and {names[-1]}",
	chartSeriesDefault:
		"{name}, series {ix} of {numSeries} with {numPoints} data {#plural(numPoints, points, point)}.",
	chartSeriesDefaultCombination:
		"{name}, series {ix} of {numSeries} with {numPoints} data {#plural(numPoints, points, point)}.",
	chartSeriesLine:
		"{name}, line {ix} of {numSeries} with {numPoints} data {#plural(numPoints, points, point)}.",
	chartSeriesLineCombination:
		"{name}, series {ix} of {numSeries}. Line with {numPoints} data {#plural(numPoints, points, point)}.",
	chartSeriesSpline:
		"{name}, line {ix} of {numSeries} with {numPoints} data {#plural(numPoints, points, point)}.",
	chartSeriesSplineCombination:
		"{name}, series {ix} of {numSeries}. Line with {numPoints} data {#plural(numPoints, points, point)}.",
	chartSeriesColumn:
		"{name}, bar series {ix} of {numSeries} with {numPoints} {#plural(numPoints, bars, bar)}.",
	chartSeriesColumnCombination:
		"{name}, series {ix} of {numSeries}. Bar series with {numPoints} {#plural(numPoints, bars, bar)}.",
	chartSeriesBar:
		"{name}, bar series {ix} of {numSeries} with {numPoints} {#plural(numPoints, bars, bar)}.",
	chartSeriesBarCombination:
		"{name}, series {ix} of {numSeries}. Bar series with {numPoints} {#plural(numPoints, bars, bar)}.",
	chartSeriesPie:
		"{name}, pie {ix} of {numSeries} with {numPoints} {#plural(numPoints, slices, slice)}.",
	chartSeriesScatterCombination:
		"{name}, series {ix} of {numSeries}, scatter plot with {numPoints} {#plural(numPoints, points, point)}.",
	chartSeriesMapCombination:
		"{name}, series {ix} of {numSeries}. Map with {numPoints} {#plural(numPoints, areas, area)}.",
	chartSeriesMapbubbleCombination:
		"{name}, series {ix} of {numSeries}. Bubble series with {numPoints} {#plural(numPoints, bubbles, bubble)}.",
	xAxisDescription: "X axis, {name}",
	yAxisDescription: "Y axis, {name}",
	contextMenuForLabel: "Context menu for",
	contextMenuEnhancedContrastLabel: "Enhanced contrast",
	contextMenuEnhancedContrastTooltip:
		"Makes series more clear by introducing patterns and other visual aids.",
	contextMenuTableViewLabel: "Open table data view",
	contextMenuTableViewTooltip: "The table data view allows you to view the data in a table format.",
};
