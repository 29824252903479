import React, { useEffect, useState } from "react";
import {
	Button,
	DataObserveKey,
	Icon,
	IconClose,
	IconEdit,
	IconStar,
	IconUnstar,
	InlineText,
	Tooltip,
	VisualComponent,
	useTranslations,
	cn,
} from "@siteimprove/fancylib";
import { invertDirection, Table } from "../../../tables-and-lists/table/table";
import { SortField } from "../../../tables-and-lists/column-header/column-header";
import { srOnlyHeader } from "../../../tables-and-lists/configs/table-config";
import { useLabTranslations } from "../../../../translations/translations";
import { Modal } from "../../../overlay/modal/modal";
import { ActionBar } from "../../../actions-and-controls/action-bar/action-bar";
import { Form } from "../../../forms-and-inputs/form/form";
import { FormElementWrapper } from "../../../forms-and-inputs/form-element-wrapper/form-element-wrapper";
import { TextArea } from "../../../forms-and-inputs/text-area/text-area";
import { Radios } from "../../../forms-and-inputs/radios/radios";
import { CheckboxGroup } from "../../../forms-and-inputs/checkbox/checkbox";
import { FormattedDate } from "../../../text/formatted-date/formatted-date";
import { InputField } from "../../../forms-and-inputs/input-field/input-field";
import { BigSmall } from "../../../text/big-small/big-small";
import { Select } from "../../../forms-and-inputs/select/select";
import * as scss from "./annotations.scss";

export enum AnnotationVisibility {
	EVERYONE = "everyone",
	ONLY_ME = "only_me",
}

export type AnnotationItem = {
	id: string | number;
	date: Date;
	comment: string;
	createdBy: string;
	visibility: AnnotationVisibility;
	isFavorite: boolean;
	visibleIn: string[];
	isSystemNotification?: boolean;
};

// ANNOTATIONS MODAL

type AnnotationModalData = {
	showModal: boolean;
	modalTitle: string;
	modalConfirmTitle: string;
	annotationData: AnnotationItem;
};

export type AnnotationsModalProps = {
	action: "create" | "update";
	show: boolean;
	modalTitle: string;
	modalConfirmTitle: string;
	closeModal: () => void;
	data: AnnotationItem;
	annotationAreasVisibleIn: AnnotationAreasVisibleIn[];
	onUpdateAnnotation: (data: Omit<Partial<AnnotationItem>, "createdBy">) => Promise<void>;
	onCreateAnnotation?: (data: Omit<AnnotationItem, "id" | "createdBy">) => Promise<void>;
	onDeleteAnnotation: (id: number | string) => Promise<void>;
};

export type DateOptions = {
	locale: string;
	skipTimezoneReset: boolean;
};

const AnnotationsModal = (props: AnnotationsModalProps): JSX.Element => {
	const i18nLab = useLabTranslations();
	const i18nLib = useTranslations();

	const {
		action,
		show,
		modalTitle,
		modalConfirmTitle,
		closeModal,
		data,
		annotationAreasVisibleIn,
		onUpdateAnnotation,
		onCreateAnnotation,
		onDeleteAnnotation,
	} = props;
	const [innerData, setInnerData] = useState(data);
	const [dateString, setDateString] = useState(innerData.date.toISOString().slice(0, 10));

	const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

	const [actionBarInProgress, setActionBarInProgress] = useState<string | null>(null);

	const onSubmit = async () => {
		try {
			action === "create"
				? await onCreateAnnotation?.(innerData)
				: await onUpdateAnnotation(innerData);

			closeModal();
		} catch (e) {
			console.error(`Error updating annotation ${data.id}: ${e}`);
		}
		setActionBarInProgress(null);
	};

	const handleDeleteAnnotation = async () => {
		try {
			setDeleteLoading(true);
			setActionBarInProgress("loading");
			await onDeleteAnnotation(data.id);
			setDeleteLoading(false);
			setActionBarInProgress(null);
			closeModal();
		} catch (e) {
			console.error(`Error updating annotation ${data.id}: ${e}`);
		}
	};

	useEffect(() => {
		setInnerData(data);
		setDateString(data.date.toISOString().slice(0, 10));
	}, [data]);

	return (
		<Modal shown={show} headerTitle={modalTitle} onClose={closeModal}>
			<Form>
				<Modal.Content>
					<FormElementWrapper name={i18nLab.annotationsDate} label={i18nLab.annotationsDate}>
						<InputField
							type="date"
							name={i18nLab.annotationsDate}
							value={dateString}
							onChange={(value: string) => {
								if (value !== "") {
									const newDate: number[] = value.split("-").map((datePart) => parseInt(datePart)); // [yyyy, mm, dd]
									setInnerData({
										...innerData,
										date: new Date(newDate[0], newDate[1] - 1, newDate[2]),
									});
									setDateString(value);
								}
							}}
						/>
					</FormElementWrapper>
					<FormElementWrapper
						name={i18nLab.annotationsVisibilityField}
						label={i18nLab.annotationsVisibilityField}
					>
						<Radios
							aria-label={i18nLab.annotationsVisibilityField}
							value={innerData.visibility}
							onChange={(value) => setInnerData({ ...innerData, visibility: value })}
						>
							<Radios.Radio value={AnnotationVisibility.EVERYONE}>{i18nLab.everyone}</Radios.Radio>
							<Radios.Radio value={AnnotationVisibility.ONLY_ME}>{i18nLab.onlyMe}</Radios.Radio>
						</Radios>
					</FormElementWrapper>
					<FormElementWrapper
						name={i18nLab.annotationsCommentField}
						label={i18nLab.annotationsCommentField}
					>
						<TextArea
							aria-label={i18nLab.annotationsCommentField}
							value={innerData.comment}
							onChange={(value) => setInnerData({ ...innerData, comment: value })}
						/>
					</FormElementWrapper>
					<FormElementWrapper
						name={i18nLab.annotationsVisibleInField}
						label={i18nLab.annotationsVisibleInField}
					>
						<CheckboxGroup
							value={innerData.visibleIn}
							onChange={(value) => setInnerData({ ...innerData, visibleIn: value })}
						>
							{annotationAreasVisibleIn.map((area, index) => (
								<CheckboxGroup.Checkbox value={area.value} key={index}>
									{area.label}
								</CheckboxGroup.Checkbox>
							))}
						</CheckboxGroup>
					</FormElementWrapper>
				</Modal.Content>
				<Modal.Footer>
					<div style={{ position: "relative" }}>
						{action === "update" && (
							<Button
								onClick={handleDeleteAnnotation}
								variant="destructive"
								className={scss.annotationsDeleteButton}
								loading={deleteLoading}
								disabled={actionBarInProgress !== null}
							>
								Delete
							</Button>
						)}
						<ActionBar
							inProgressAction={actionBarInProgress}
							primary={{
								children: modalConfirmTitle,
								onClick: (actionId) => {
									setActionBarInProgress(actionId);
									onSubmit();
								},
							}}
							cancel={{
								children: i18nLib.cancel,
								onClick: () => closeModal(),
							}}
						/>
					</div>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

// NO ANNOTATIONS

type NoAnnotationsProps = {
	currentFilter: AnnotationFilter;
};

const NoAnnotations = (props: NoAnnotationsProps): JSX.Element => {
	const i18nLab = useLabTranslations();

	const { currentFilter } = props;

	let mainText;
	let helpText;
	switch (currentFilter) {
		case AnnotationFilter.ALL:
			mainText = i18nLab.noAnnotationsForThisChart;
			helpText = i18nLab.createAnnotationHelpText;
			break;
		case AnnotationFilter.CHART_PERIOD:
			mainText = i18nLab.noAnnotationsForChartPeriod;
			helpText = i18nLab.createAnnotationHelpText;
			break;
		case AnnotationFilter.FAVORITE:
			mainText = i18nLab.noAnnotationsForFavorite;
			helpText = i18nLab.noAnnotationsHelpTextForFavorite;
			break;
	}

	return (
		<div className={scss.noAnnotations}>
			<h4>{mainText}</h4>
			<InlineText size="xSmall">{helpText}</InlineText>
		</div>
	);
};

// ANNOTATIONS TABLE

type AnnotationsTableProps = {
	visibleAnnotationsData: AnnotationItem[];
	updateAnnotationsVisibleData: (visibleAnnotationsData: AnnotationItem[]) => void;
	dateOptions: DateOptions;
	highlightedIds: (string | number)[];
	onUpdateAnnotation: (data: Omit<Partial<AnnotationItem>, "createdBy">) => Promise<void>;
	updateAnnotationsModalData: (updatedAnnotationsModalData: AnnotationModalData) => void;
	updateModalAction: (action: "create" | "update") => void;
};

const AnnotationsTable = (props: AnnotationsTableProps): JSX.Element => {
	const i18n = useLabTranslations();

	const {
		visibleAnnotationsData,
		updateAnnotationsVisibleData,
		dateOptions,
		highlightedIds,
		onUpdateAnnotation,
		updateAnnotationsModalData,
		updateModalAction,
	} = props;

	const [sort, setSort] = useState<SortField<typeof visibleAnnotationsData[0]>>({
		property: "date",
		direction: "desc",
	});
	const [loading, setLoading] = useState(true);

	const updateAnnotation = async (changedData: AnnotationItem) => {
		try {
			await onUpdateAnnotation(changedData);
		} catch (e) {
			console.error(`Error updating annotation ${changedData.id}: ${e}`);
		}
	};

	const annotationVisibilityText: {
		[key in AnnotationVisibility]: { text: string; tooltip?: string };
	} = {
		[AnnotationVisibility.EVERYONE]: {
			text: i18n.everyone,
			tooltip: i18n.annotationsGlobalVisibilityTooltip,
		},
		[AnnotationVisibility.ONLY_ME]: { text: i18n.onlyMe, tooltip: undefined },
	};

	const currentFavoriteIds = visibleAnnotationsData
		.map((annotation) => (annotation.isFavorite ? annotation.id : undefined))
		.filter((id) => id !== undefined);
	const [favoriteIds, setFavoriteIds] = useState(currentFavoriteIds);

	useEffect(() => {
		const currentFavoriteIds = visibleAnnotationsData
			.map((annotation) => (annotation.isFavorite ? annotation.id : undefined))
			.filter((id) => id !== undefined);
		setFavoriteIds(currentFavoriteIds);
	}, [visibleAnnotationsData]);

	const handleFavoriteClick = (currentAnnotation: AnnotationItem) => {
		// Handle Optmistic feedback for the favorite icon
		if (!currentAnnotation.isFavorite) {
			setFavoriteIds([...favoriteIds, currentAnnotation.id]);
		} else {
			const favoriteIdsWithoutCurrentAnnotation = favoriteIds.filter(
				(id) => id !== currentAnnotation.id
			);
			setFavoriteIds(favoriteIdsWithoutCurrentAnnotation);
		}
		// Do the actual update on the annotation (no need for async call)
		updateAnnotation({ ...currentAnnotation, isFavorite: !currentAnnotation.isFavorite });
	};

	useEffect(() => {
		setLoading(true);
		updateAnnotationsVisibleData(sortItems(visibleAnnotationsData, sort));
		setLoading(false);
	}, [sort, visibleAnnotationsData]);

	const hasEditableAnnotations = visibleAnnotationsData.some((a) => !a.isSystemNotification);

	return (
		<Table
			className="chart-annotations-table"
			columns={[
				{
					header: srOnlyHeader(i18n.favorite),
					render: (dto) => (
						<Button
							onClick={() => handleFavoriteClick(dto)}
							aria-pressed={dto.isFavorite}
							aria-label={i18n.favoriteAnnotation}
						>
							<Icon>{favoriteIds.includes(dto.id) ? <IconStar /> : <IconUnstar />}</Icon>
						</Button>
					),
				},
				{
					header: {
						property: "date",
						content: i18n.annotationsDate,
						defaultSortDirection: "desc",
					},
					render: (dto) => (
						<FormattedDate
							date={dto.date}
							locale={dateOptions.locale}
							skipTimezoneReset={dateOptions.skipTimezoneReset}
						/>
					),
				},
				{
					header: {
						property: "comment",
						content: i18n.annotationsComment,
					},
					render: (dto) => dto.comment,
				},
				{
					header: {
						property: "createdBy",
						content: i18n.annotationsCreatedBy,
						notSortable: true,
					},
					render: (dto) =>
						dto.isSystemNotification ? (
							<BigSmall big="Siteimprove" small={i18n.annotationsSystemNotificationHelpText} />
						) : (
							dto.createdBy
						),
				},
				{
					header: {
						property: "visibility",
						content: i18n.annotationsVisibility,
						notSortable: true,
					},
					render: (dto) => {
						const { text, tooltip } = annotationVisibilityText[dto.visibility];
						return tooltip ? (
							<Tooltip variant={{ type: "text" }} content={tooltip}>
								{text}
							</Tooltip>
						) : (
							text
						);
					},
				},
				hasEditableAnnotations && {
					header: srOnlyHeader(i18n.edit),
					render: (dto) =>
						!dto.isSystemNotification && (
							<Button
								onClick={() => {
									updateModalAction("update");
									updateAnnotationsModalData({
										showModal: true,
										modalTitle: i18n.editAnnotation,
										modalConfirmTitle: i18n.saveAnnotation,
										annotationData: dto,
									});
								}}
								aria-label={i18n.editAnnotation}
							>
								<Icon>
									<IconEdit />
								</Icon>
							</Button>
						),
				},
			]}
			items={visibleAnnotationsData}
			sort={sort}
			setSort={(property, direction) => {
				setLoading(true);
				setSort({
					property: property,
					direction: property === sort.property ? invertDirection(sort.direction) : direction,
				});
			}}
			loading={loading}
			rowKey={(dto) => dto.id}
			highlightRow={(annotation) => highlightedIds.includes(annotation.id.toString())}
			withoutKeyColumn={true}
		/>
	);
};

// ANNOTATIONS

enum AnnotationFilter {
	ALL = "all",
	CHART_PERIOD = "chart-period",
	FAVORITE = "favorite",
}

export type AnnotationAreasVisibleIn = {
	label: string;
	value: string;
};

export type AnnotationsProps = {
	showAnnotations: boolean;
	hideAnnotations: (showAnnotations: boolean) => void;
	annotationsData: AnnotationItem[];
	onUpdateAnnotation: (data: Omit<Partial<AnnotationItem>, "createdBy">) => Promise<void>;
	onCreateAnnotation?: (data: Omit<AnnotationItem, "id" | "createdBy">) => Promise<void>;
	chartPeriod: Date[];
	dateOptions: DateOptions;
	onDeleteAnnotation: (id: string | number) => Promise<void>;
	highlightedIds: (string | number)[];
	annotationAreasVisibleIn: AnnotationAreasVisibleIn[];
} & DataObserveKey &
	VisualComponent;

export function Annotations(props: AnnotationsProps): JSX.Element {
	const {
		showAnnotations,
		hideAnnotations,
		annotationsData,
		onUpdateAnnotation,
		onCreateAnnotation,
		onDeleteAnnotation,
		chartPeriod,
		dateOptions,
		highlightedIds,
		annotationAreasVisibleIn,
		className,
		style,
	} = props;

	const i18n = useLabTranslations();

	const newAnnotationModalData: AnnotationModalData = {
		showModal: false,
		modalTitle: i18n.newAnnotation,
		modalConfirmTitle: i18n.createAnnotation,
		annotationData: {
			id: "",
			date: new Date(),
			comment: "",
			createdBy: "",
			visibility: AnnotationVisibility.ONLY_ME,
			isFavorite: false,
			visibleIn: [],
		},
	};

	const [annotationsFilterOption, setAnnotationsFilterOption] = useState<AnnotationFilter>();
	const [visibleAnnotations, setVisibleAnnotations] = useState(annotationsData);
	const hasAnnotations = visibleAnnotations.length > 0;

	const [annotationsModalData, setAnnotationsModalData] = useState(newAnnotationModalData);
	const [modalAction, setModalAction] = useState<"create" | "update">("create");

	useEffect(() => {
		if (annotationsFilterOption === AnnotationFilter.FAVORITE) {
			const favoriteAnnotations: AnnotationItem[] = annotationsData.filter(
				(item) => item.isFavorite
			);
			setVisibleAnnotations(favoriteAnnotations);
		} else if (annotationsFilterOption === AnnotationFilter.CHART_PERIOD) {
			const annotationsWithinChartPeriod: AnnotationItem[] = annotationsData.filter(
				(item) => item.date >= chartPeriod[0] && item.date <= chartPeriod[1]
			);
			setVisibleAnnotations(annotationsWithinChartPeriod);
		} else {
			setVisibleAnnotations(annotationsData);
		}
	}, [annotationsData, annotationsFilterOption]);

	return (
		<>
			<AnnotationsModal
				action={modalAction}
				show={annotationsModalData.showModal}
				modalTitle={annotationsModalData.modalTitle}
				modalConfirmTitle={annotationsModalData.modalConfirmTitle}
				closeModal={() => setAnnotationsModalData({ ...annotationsModalData, showModal: false })}
				data={annotationsModalData.annotationData}
				annotationAreasVisibleIn={annotationAreasVisibleIn}
				onUpdateAnnotation={onUpdateAnnotation}
				onCreateAnnotation={onCreateAnnotation}
				onDeleteAnnotation={onDeleteAnnotation}
			/>
			<div
				className={cn(className, showAnnotations ? scss.annotations : scss.hideAnnotations)}
				style={style}
			>
				<div className={scss.annotationsTableTop}>
					<div className={scss.annotationsTableTopButtonsWrapper}>
						{onCreateAnnotation && (
							<Button
								onClick={() => {
									setModalAction("create");
									setAnnotationsModalData({ ...newAnnotationModalData, showModal: true });
								}}
							>
								{i18n.newAnnotation}
							</Button>
						)}
						<div className={scss.annotationsChartPeriod}>
							<span style={{ marginRight: "0.5rem" }}>{i18n.annotationsShowFilter}</span>
							<Select
								aria-label={i18n.annotationsShowFilter}
								items={[
									{ title: i18n.allAnnotations, value: AnnotationFilter.ALL },
									{ title: i18n.chartPeriodAnnotations, value: AnnotationFilter.CHART_PERIOD },
									{
										title: i18n.favoriteAnnotations,
										value: AnnotationFilter.FAVORITE,
										icon: (
											<Icon>
												<IconStar />
											</Icon>
										),
									},
								]}
								value={annotationsFilterOption}
								onChange={setAnnotationsFilterOption}
								defaultOption={AnnotationFilter.CHART_PERIOD}
							/>
						</div>
					</div>
					<div>
						<Button
							onClick={() => {
								hideAnnotations(false);
							}}
							variant="borderless"
							aria-label={i18n.closeAnnotations}
						>
							<Icon size="large">
								<IconClose />
							</Icon>
						</Button>
					</div>
				</div>
				{hasAnnotations ? (
					<AnnotationsTable
						visibleAnnotationsData={visibleAnnotations}
						updateAnnotationsVisibleData={setVisibleAnnotations}
						dateOptions={dateOptions}
						onUpdateAnnotation={onUpdateAnnotation}
						updateAnnotationsModalData={setAnnotationsModalData}
						updateModalAction={setModalAction}
						highlightedIds={highlightedIds}
					/>
				) : (
					<NoAnnotations currentFilter={annotationsFilterOption as AnnotationFilter} />
				)}
			</div>
		</>
	);
}

function sortItems<TDto>(items: TDto[], sort: SortField<TDto>): TDto[] {
	return items.sort((a, b) => {
		const first = sort.direction === "asc" ? a : b;
		const second = sort.direction === "asc" ? b : a;
		const v1 = first[sort.property];
		const v2 = second[sort.property];
		if (typeof v1 === "string" && typeof v2 === "string") {
			return v1.localeCompare(v2, undefined, {
				numeric: true,
				sensitivity: "base",
			});
		}

		return v1 > v2 ? 1 : v2 > v1 ? -1 : 0;
	});
}
